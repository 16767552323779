/**
 * 姓名回显脱敏
 * @param {string} input 待脱敏的姓名数据
 * @returns {string}
 */

function nameFilter(input) {
  if (input === undefined || input.length < 2) {
    return input;
  }
  let name = '';
  const length = input.length;
  if (length === 2) {
    name = '*' + input[1];
  } else {
    for (let i = 2; i < length; i++) {
      name = name + '*';
    }
    name = input[0] + name + input[length - 1];
  }
  return name;
}

export default {
  nameFilter,
};
