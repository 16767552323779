import remote from '@/services/remote';

//验证码校验
export const checkRegCaptcha = async (payload) => {
  try {
    let result = await remote.post('/wx/auth/check-code', {
      payload,
    });
    return result;
  } catch (error) {
    console.warn(error);
  }
};
//邮箱验证码
export const checkEmail = async (payload) => {
  try {
    let result = await remote.get('/wx/auth/captcha-email', {
      payload,
    });
    return result;
  } catch (error) {
    console.warn(error);
  }
};
//用户信息校验
export const checkUserInfo = async (payload) => {
  try {
    let result = await remote.post('/wx/auth/check-userInfo', {
      payload,
    });
    return result;
  } catch (error) {
    console.warn(error);
  }
};

//手机号找回密码
export const forgetByCode = async (payload) => {
  try {
    let result = await remote.post('/wx/auth/forget-password-code', {
      payload,
    });
    return result;
  } catch (error) {
    console.warn(error);
  }
};
//邮箱找回密码
export const forgetByEmail = async (payload) => {
  try {
    let result = await remote.post('/wx/auth/forget-password-email', {
      payload,
    });
    return result;
  } catch (error) {
    console.warn(error);
  }
};

//用户信息找回密码
export const forgetByUserInfo = async (payload) => {
  try {
    let result = await remote.post('/wx/auth/forget-password-userInfo', {
      payload,
    });
    return result;
  } catch (error) {
    console.warn(error);
  }
};
