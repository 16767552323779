var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", { staticClass: "layout-footer" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "footer_top" }, [
        _c("div", { staticClass: "footer_left" }, [
          _vm._m(0),
          _c("div", { staticClass: "footer_us" }, [
            _c("span", { staticClass: "cursor", on: { click: _vm.aboutUs } }, [
              _vm._v("关于我们")
            ]),
            _c(
              "span",
              { staticClass: "cursor", on: { click: _vm.contactUs } },
              [_vm._v("联系我们")]
            )
          ])
        ]),
        _vm._m(1)
      ]),
      _c("div", { staticClass: "footer_bottom" }, [
        _vm._v(
          " Copyright ©2024 www.airflydream.com 北京优百惠科技有限公司 版权所有 | "
        ),
        _c("span", { staticClass: "cursor", on: { click: _vm.gobeian } }, [
          _vm._v("京ICP备19019961号-2")
        ]),
        _c(
          "span",
          { staticClass: "cursor wangan", on: { click: _vm.gowangan } },
          [_vm._v("京公网安备11010202010893")]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "footer_logo" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/bottomLogo.png"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "footer_right" }, [
      _c("div", { staticClass: "footer_right_qrcode" }, [
        _c("div", { staticClass: "footer_r_q" }, [
          _c("div", { staticClass: "footer_xcx" }, [
            _c("img", {
              attrs: {
                src: require("@/assets/images/bottomLogo1.jpg"),
                alt: ""
              }
            }),
            _c("span", [_vm._v("公众号")])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }