<template>
  <h1 class="Logo">
    <a href="/">
      <img :src="LogoPng" alt="" />
      <span>{{ title }}</span>
    </a>
  </h1>
</template>

<script>
import LogoPng from '@/assets/images/logo.png';
export default {
  name: 'Logo',
  data() {
    return {
      LogoPng,
      title: '',
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.Logo {
  float: left;
  padding: 10px 0;
  box-sizing: border-box;
  img {
    width: 100%;
    height: 100%;
    // vertical-align: -webkit-baseline-middle;
  }
}
</style>
