<template>
  <header :class="$router.app._route.name === 'index' ? 'layout-header' : 'layout-header fixed'">
    <div class="container">
      <Logo />
      <Nav class="nav" />
      <User />
    </div>
  </header>
</template>

<script>
import Logo from './logo.vue';
import Nav from './nav.vue';
import User from './user.vue';
export default {
  name: 'layout-header',
  components: { Logo, Nav, User },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.layout-header {
  width: 100%;
  background-color: rgb(13 86 184);
  height: 70px;
  position: relative;
  z-index: 2024;
  .container {
    width: $base_width;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    .nav {
      width: 80%;
      align-items: center;
    }
  }
}
</style>
