import Vue from 'vue';
import App from './App';
import 'flex.css';
import './common';
import '@/assets/style/index.scss';
import router from './router';
import store from '@/store/index';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueLazyLoad from 'vue-lazyload';
import 'amfe-flexible';
// 全局组件挂载
import nodata from '@/components/noData';
// import Cloudtop from 'cloudtop';
import './common/drag';
// new Cloudtop({
//   baseURL: 'http://116.63.139.209:3000',
//   projectId: 'xinces',
//   env: 'production',
//   reportType: 'beacon',
// });
Vue.use(VueLazyLoad);
Vue.use(ElementUI);
Vue.component('nodata', nodata);

import message from './utils/optimizePop.js'; //引入
Vue.prototype.$message = message;
ElementUI.Dialog.props.lockScroll.default = false;

new Vue({
  name: 'app',
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
