<template>
  <div class="banner_bar_wrapper">
    <div
      class="banner_bar_item"
      v-for="item in bannerItemData"
      :key="item.id"
      :style="{ background: `url(${item.bgImg}) no-repeat center`, backgroundSize: 'cover' }"
    >
      <div class="item_top">
        <div class="item_title">{{ item.title }}</div>
        <div class="item_sub_title_wrapper">
          <div
            @click="itemSubActive = itemSub.id"
            :class="itemSubActive === itemSub.id ? 'item_sub_title actice' : 'item_sub_title'"
            v-for="itemSub in item.subTitle"
            :key="itemSub.id"
          >
            {{ itemSub.name }}
          </div>
        </div>
      </div>

      <div class="item_content">
        <div class="item" v-for="(itemList, index) in 2" :key="index">
          <div class="left">2024河北省青少年航赛</div>
          <div class="right">{{ btnLabel | filterLabel(item.id) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner1 from '@/assets/images/bannerBar_01.png';
import Banner2 from '@/assets/images/bannerBar_02.png';
import Banner3 from '@/assets/images/bannerBar_03.png';
export default {
  name: 'BannerBar',
  filters: {
    filterLabel: function(s, val) {
      switch (val) {
        case 1:
          return '加入';
        case 2:
          return '报名';
        default:
          return '下载';
      }
    },
  },
  data() {
    return {
      itemSubActive: 1,
      bannerItemData: [
        {
          id: 1,
          bgImg: Banner1,
          title: '社群',
          subTitle: [
            {
              id: 1,
              name: '微信群',
            },
            {
              id: 2,
              name: 'QQ群',
            },
          ],
        },
        {
          id: 2,
          bgImg: Banner2,
          title: '热门实训',
        },
        {
          id: 3,
          bgImg: Banner3,
          title: '必备软件',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.banner_bar_wrapper {
  width: 100%;
  height: 170px;
  display: flex;
  .banner_bar_item {
    width: 410px;
    height: 100%;
    padding: 37px 90px 42px 31px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .item_top {
      width: 100%;
      height: 20px;
      display: flex;
      padding-right: 87px;
      box-sizing: border-box;
      justify-content: space-between;
      align-items: flex-end;
    }
    .item_title {
      height: 20px;
      font-family: MicrosoftYaHei, MicrosoftYaHei;
      font-weight: bold;
      font-size: 20px;
      color: #333333;
      line-height: 20px;
      text-align: left;
      font-style: normal;
    }

    .item_sub_title_wrapper {
      flex: 1;
      display: flex;
      height: 14px;
      justify-content: flex-end;
      .item_sub_title {
        margin-right: 14px;
        height: 14px;
        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-weight: bold;
        font-size: 14px;
        color: rgba(51, 51, 51, 0.3);
        line-height: 14px;
        text-align: left;
        font-style: normal;
      }
    }
    .actice {
      color: #333333 !important;
    }
    .item_content {
      margin-top: 15px;
      flex: 1;
      .item {
        display: flex;
        height: 20px;
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        .left {
          flex: 1;
          font-family: MicrosoftYaHei;
          font-size: 14px;
          color: #0d65da;
          text-align: left;
          font-style: normal;
          padding-left: 18px;
          box-sizing: border-box;
          position: relative;
          &:before {
            content: '';
            position: absolute;
            left: 0;

            top: 0;
            bottom: 0;
            margin: auto;
            width: 12px;
            height: 12px;
            background: url('~@/assets/images/dot.png') no-repeat;
            background-size: contain;
          }
        }
        .right {
          width: 60px;
          height: 20px;
          background: #2c71d8;
          line-height: 20px;
          border-radius: 4px;
          font-family: MicrosoftYaHei, MicrosoftYaHei;
          font-weight: bold;
          font-size: 12px;
          color: #ffffff;
          font-style: normal;
          text-align: center;
        }
      }
    }
  }
}
</style>
