var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "form",
      staticClass: "reset_form",
      attrs: { model: _vm.form, rules: _vm.rules }
    },
    [
      _c(
        "div",
        { staticClass: "reset-step" },
        [
          _c(
            "el-steps",
            { attrs: { active: _vm.active } },
            [_c("el-step"), _c("el-step"), _c("el-step")],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "reset_title" }, [_vm._v("找回密码")]),
      _vm.active === 1
        ? _c(
            "div",
            [
              _c("div", { staticClass: "reset_text" }, [
                _vm._v(
                  "输入 “" +
                    _vm._s(_vm.typeText) +
                    "” 填写验证信息，通过验证后即可重设密码。"
                )
              ]),
              _vm.resetType === 1
                ? _c("div", [
                    _c("div", { staticClass: "register_role" }, [
                      _c("div", { staticClass: "register_role_title" }, [
                        _vm._v("请选择角色")
                      ]),
                      _c(
                        "div",
                        { staticClass: "register_role_wrapper" },
                        _vm._l(_vm.roles, function(item) {
                          return _c(
                            "div",
                            {
                              key: item.id,
                              class:
                                _vm.form.identity === item.id
                                  ? "register_role_item is_active"
                                  : "register_role_item",
                              on: {
                                click: function($event) {
                                  return _vm.handleChangeRole(item)
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(item.label) + " ")]
                          )
                        }),
                        0
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.resetType === 1
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "phoneNumber",
                      attrs: { prop: "phoneNumber" }
                    },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: { placeholder: "请输入手机号码" },
                          model: {
                            value: _vm.form.phoneNumber,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "phoneNumber", $$v)
                            },
                            expression: "form.phoneNumber"
                          }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "prepend" }, slot: "prepend" },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.value,
                                    callback: function($$v) {
                                      _vm.value = $$v
                                    },
                                    expression: "value"
                                  }
                                },
                                _vm._l(_vm.options, function(item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.resetType === 2
                ? _c(
                    "el-form-item",
                    { attrs: { prop: "email" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入注册的邮箱" },
                        model: {
                          value: _vm.form.email,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "email", $$v)
                          },
                          expression: "form.email"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.resetType === 3
                ? _c(
                    "el-form-item",
                    { attrs: { prop: "userName" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入注册登录名" },
                        model: {
                          value: _vm.form.userName,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "userName", $$v)
                          },
                          expression: "form.userName"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.resetType === 3
                ? _c(
                    "el-form-item",
                    { attrs: { prop: "nickName" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入注册用户姓名或单位名称" },
                        model: {
                          value: _vm.form.nickName,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "nickName", $$v)
                          },
                          expression: "form.nickName"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.resetType === 3
                ? _c(
                    "el-form-item",
                    { attrs: { prop: "teamName" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入参赛队名称" },
                        model: {
                          value: _vm.form.teamName,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "teamName", $$v)
                          },
                          expression: "form.teamName"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.resetType !== 3
                ? _c(
                    "el-form-item",
                    { attrs: { prop: "code" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: { placeholder: "请输入验证码" },
                          model: {
                            value: _vm.form.code,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "code", $$v)
                            },
                            expression: "form.code"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "suffix_code",
                              attrs: { slot: "suffix" },
                              on: { click: _vm.startDuration },
                              slot: "suffix"
                            },
                            [_vm._v(_vm._s(_vm.duration))]
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "reset-office" }, [
                _c("div", { staticClass: "left" }, [
                  _vm.resetType !== 2
                    ? _c("div", { on: { click: _vm.byEmail } }, [
                        _vm._v("通过邮箱找回")
                      ])
                    : _c("div", { on: { click: _vm.byNumber } }, [
                        _vm._v("通过手机找回")
                      ])
                ]),
                _c("div", { staticClass: "right" }, [
                  _vm.resetType !== 3
                    ? _c("div", { on: { click: _vm.byUser } }, [
                        _vm._v("通过登录名验证信息找回")
                      ])
                    : _c("div", { on: { click: _vm.byNumber } }, [
                        _vm._v("通过手机找回")
                      ])
                ])
              ]),
              _c(
                "div",
                {
                  staticClass: "btn",
                  on: {
                    click: function($event) {
                      return _vm.next(1)
                    }
                  }
                },
                [_vm._v("下一步")]
              ),
              _c("div", { staticClass: "info" }, [
                _c("span", [_vm._v("手机号已不再使用？")]),
                _c("span", [_vm._v("点此解决")])
              ])
            ],
            1
          )
        : _vm.active === 2
        ? _c(
            "div",
            [
              _c("div", { staticClass: "reset_text" }, [
                _vm._v("请重新设置新的密码")
              ]),
              _c(
                "el-form-item",
                { attrs: { prop: "password" } },
                [
                  _c("el-input", {
                    attrs: {
                      "show-password": "",
                      placeholder: "请输入密码",
                      type: "password"
                    },
                    model: {
                      value: _vm.form.password,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "password", $$v)
                      },
                      expression: "form.password"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "btn",
                  on: {
                    click: function($event) {
                      return _vm.next(2)
                    }
                  }
                },
                [_vm._v("确定修改密码")]
              )
            ],
            1
          )
        : _c("div", [
            _c("div", { staticClass: "reset_success" }, [
              _vm._v("恭喜您，密码重置成功！")
            ]),
            _c(
              "div",
              { staticClass: "btn", on: { click: _vm.handleChangeMode } },
              [_vm._v("返回登录")]
            )
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }