var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "slider_wrapper" },
    [
      _c(
        "el-menu",
        {
          staticClass: "slider_menu",
          attrs: {
            "default-openeds": _vm.defaultOpeneds,
            "default-active": _vm.activeIndexSlider
          },
          on: { select: _vm.handleSelect }
        },
        _vm._l(_vm.topChildSlidRouters[_vm.topName], function(item, index) {
          return _c("MenuItem", {
            key: item.path + index,
            attrs: { type: "slider", "base-path": item.path, item: item }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }