<template>
  <div>
    <div class="school_list" v-if="list.length < 6">
      <div class="school_wrappers" @click="handlePush(item)" v-for="(item, index) in list" :key="item">
        <div :class="list.length > 3 && (index === 0 || index === list.length - 1) ? 'school_body_mask' : ''">
          <div class="swiper_top">
            <img :src="item.picture || require('@/assets/images/pic_03.png')" alt="" />
          </div>
          <div class="swiper_content">
            <div class="swiper_content_title">{{ item.schoolName }}</div>
            <div class="swiper_content_value">
              {{ item.intro }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="school_swiper_i">
      <div class="wrappers">
        <div class="swiper1">
          <div class="swiper-wrapper">
            <div class="swiper-slide " @click="handlePush(item)" v-for="item in list" :key="item">
              <div class="body"></div>
              <div class="swiper_top">
                <img :src="item.picture || require('@/assets/images/pic_03.png')" alt="" />
              </div>
              <div class="swiper_content">
                <div class="swiper_content_title">{{ item.schoolName }}</div>
                <div class="swiper_content_value">
                  {{ item.intro }}
                </div>
              </div>
            </div>
          </div>
          <!-- 如果需要分页器 -->
          <div class="swiper-pagination"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';
import 'swiper/swiper-bundle';
import { schoolList } from '@/api/index.js';
export default {
  name: 'SchoolSwiper',
  data() {
    return {
      mySwiper: null,
      transform: 0,
      list: [],
    };
  },
  mounted() {
    this.getScoolList();
    window.addEventListener('popstate', this.initWrapper);
    this.initWrapper();
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.initWrapper);
  },
  methods: {
    initWrapper() {
      setTimeout(() => {
        this.mySwiper = new Swiper('.swiper1', {
          loop: this.list.length > 5 ? true : false, // 循环模式选项
          spaceBetween: 21,
          slidesPerView: 5,
          autoplay: {
            delay: 2500,
          },
          width: window.screen.width,
          slideNextClass: 'no-mask',
          // slidePrevClass:"no-mask",
          // slideActiveClass: 'no-mask',
          // 如果需要分页器
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },

          observer: true,
          // 如果需要前进后退按钮
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          // slideWidth: 386,
          // slideClass: 'custom-slide',
          // 如果需要滚动条
          scrollbar: {
            //   el: '.swiper-scrollbar',
          },
          // slidesOffsetBefore: 400,
          // slidesOffsetAfter: 400,
          on: {
            beforeLoopFix: function() {
              // 如果滑块不够
              if (this.slides.length < 5) {
                // 禁止循环修复
                this.loopedSlides = 0;
                this.loopDestroy();
              }
            },
            init: () => {
              setTimeout(() => {
                const n = document.querySelectorAll('.no-mask');
                n[n.length - 1].nextElementSibling.classList.add('no-mask');
                if (n[n.length - 1].nextElementSibling.nextElementSibling) {
                  n[n.length - 1].nextElementSibling.nextElementSibling.classList.add('no-mask');
                }
              }, 300);
            },
            slideChangeTransitionStart: () => {
              this.$nextTick(() => {
                let doc = document.getElementsByClassName('swiper-slide-prev');
                if (doc.length <= 0) return;
                // const parentElement = doc[0]?.parentNode; // 获取当前元素的父元素;
                // let width = doc[0]?.clientWidth;
                // if (this.transform < 3 * width) {
                //   this.transform += width;
                // }
                // parentElement.style.transform = `translate3d(-${this.transform + 42}px, 0px, 0px)`;
                // const siblings = Array.from(parentElement?.children); // 将子元素集合转换为数组
                // siblings.forEach((f) => {
                //   f.classList.add('mask');
                //   f.classList.remove('no-mask');
                // });
                setTimeout(() => {
                  // const nextSibling1 = doc[0].nextElementSibling; // 获取下一个相邻元素
                  // const preSibling2 = doc[0].previousElementSibling; // 获取下一个相邻元素的下一个相邻元素
                  // nextSibling1.classList.add('no-mask');
                  // preSibling2.classList.add('no-mask');
                  // preSibling2.previousElementSibling.classList.add('no-mask');
                  const n1 = document.querySelectorAll('.no-mask')[0];
                  n1.nextElementSibling.classList.add('no-mask');
                  n1.nextElementSibling.nextElementSibling.classList.add('no-mask');
                }, 0);
              });
            },
          },
        });
      }, 500);
    },
    async getScoolList() {
      let result = await schoolList({
        pageNo: 1,
        pageSize: 16,
      });
      this.list = result.data.items;
    },
    handlePush(e) {
      // 跳转
      this.$router.push({
        path: '/schoolstyleDetail',
        query: {
          id: e.id,
        },
      });
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/style/common.scss';
@import '@/assets/style/layout.scss';

.school_list {
  width: 100%;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  .school_wrappers {
    min-width: 386px;
    max-width: 386px;
    height: 100%;
    margin-right: 21px;
    cursor: pointer;
    .school_body_mask {
      position: relative;
    }
    .school_body_mask::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 464px;
      background: rgba($color: #000000, $alpha: 0.5);
      z-index: 9;
      border-radius: 0 0 13px 13px;
    }
    .swiper_top {
      width: 100%;
      height: 217px;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .swiper_content {
      width: 100%;
      height: 300px;
      background: url('~@/assets/images/HxBK_01.png') no-repeat center;
      background-size: cover;
      transform: translateY(-53px);
      padding: 24px;
      box-sizing: border-box;

      .swiper_content_title {
        font-weight: bold;
        font-size: 22px;
        color: #333333;
        line-height: 29px;
        text-align: left;
        font-style: normal;
        word-wrap: break-word; /* 允许在长单词内部换行 */
        word-break: break-all; /* 允许在任意位置换行 */
      }

      .swiper_content_value {
        height: 190px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 8;
        overflow: hidden;
        font-size: 14px;
        color: #333333;
        line-height: 24px;
        text-align: justify;
        margin-top: 12px;
        margin-bottom: 12px;
        font-style: normal;
      }
    }
  }
}
.school_swiper_i {
  // width: 100vw;
  // flex: 1;
  transform: translateX(-386px);
  // transform: translateX(calc(($base_width - 100vw) / 2));
  // overflow: hidden;

  .wrappers {
    width: 100%;
    position: relative;
  }
  .swiper1 {
    height: 516px;
    .swiper-pagination {
      transform: translateX(386px);
    }
    .swiper-wrapper {
      height: 100%;

      .swiper-slide,
      .custom-slide {
        position: relative;
        height: 464px;
        cursor: pointer;

        // margin-right: 16px;
        // transform: translateX(-152px);
        .body {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba($color: #000000, $alpha: 0.5);
          z-index: 9;
          transform: translateX(0);
        }

        .swiper_top {
          width: 100%;
          height: 217px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .swiper_content {
          width: 100%;
          height: 300px;
          background: url('~@/assets/images/HxBK_01.png') no-repeat center;
          background-size: cover;
          transform: translateY(-53px);
          padding: 24px;
          box-sizing: border-box;

          .swiper_content_title {
            font-weight: bold;
            font-size: 22px;
            color: #333333;
            line-height: 29px;
            text-align: left;
            font-style: normal;
            word-wrap: break-word; /* 允许在长单词内部换行 */
            word-break: break-all; /* 允许在任意位置换行 */
          }

          .swiper_content_value {
            height: 190px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 8;
            overflow: hidden;
            font-size: 14px;
            color: #333333;
            line-height: 24px;
            text-align: justify;
            margin-top: 12px;
            margin-bottom: 12px;
            font-style: normal;
          }
        }
      }
      .swiper-slide:first-child {
        margin-left: 26px;
      }

      .swiper-slide-next {
        & ～ div {
          .body {
            background: transparent;
          }
        }

        .body {
          background: transparent;
        }
      }

      .no-mask {
        .body {
          background: transparent;
        }
      }
    }
  }
}
</style>
