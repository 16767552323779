import errorCodes from '../../config/error-codes';
import errors from './errors';
import route from '@/router';

// import { sessionCache } from '@csii/vx-util';

export const transformRequest = function(config) {
  const extendArgs = {
    // 公共参数
  };

  if (config.method === 'get') {
    config.params = Object.assign({}, config.params || {}, extendArgs);
  } else {
    config.data = Object.assign({}, config.data || {}, extendArgs);
  }
  config.headers['X-Litemall-Admin-Token'] = localStorage.getItem('web-token');

  return config;
};

export const transformResponse = function(response) {
  let respData = response.data;
  // if (!respData.code) {
  //   console.warn('丢失错误码...', response.config.url);
  // }
  return respData;
  // 业务逻辑错误
  // throw new errors.BuessinessError(respData.respCode, respData.respMessage);
};

const validateStatus = (status) => status >= 200 && status < 300;

export const catchError = function(error) {
  error.response.status === 500 && route.replace({ name: '500' });
  if (error.response.status === 501) {
    // 强制登出到登录页
    route.replace({ path: '/login' });
  }

  // axios 可能抛出的错误是 https://github.com/axios/axios/search?q=createError&unscoped_q=createError
  if (error && (error.confg || error.response)) {
    let message = error.message;
    const response = error.response;
    // http协议层错误处理 https://github.com/axios/axios/blob/885ada6d9b87801a57fe1d19f57304c315703079/lib/core/settle.js#L17
    if (response && !validateStatus(response.status)) {
      message = errorCodes.network[response.status] || response.statusText;
      throw new errors.NetworkError(response.status, message);
    }
    if (/timeout/.test(message)) {
      // 客户端请求超时 https://github.com/axios/axios/blob/dc4bc49673943e35280e5df831f5c3d0347a9393/lib/adapters/xhr.js#L95
      message = '请求超时！';
    } else if (/aborted/.test(message)) {
      // 客户端主动取消 https://github.com/axios/axios/blob/dc4bc49673943e35280e5df831f5c3d0347a9393/lib/adapters/xhr.js#L73
      message = '请求被取消！';
    } else if (/^Network/.test(message)) {
      // 客户端网络连接失败 https://github.com/axios/axios/blob/dc4bc49673943e35280e5df831f5c3d0347a9393/lib/adapters/xhr.js#L83
      message = '网络连接错误！';
    } else {
      message = '未定义错误!!!';
    }
    throw new errors.ClientError(message);
  } else {
    throw error;
  }
};
