var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "form",
      staticClass: "register_form",
      attrs: { model: _vm.form, rules: _vm.rules }
    },
    [
      _c("div", { staticClass: "register_title" }, [_vm._v("注册账号")]),
      _c("div", { staticClass: "register_role" }, [
        _c("div", { staticClass: "register_role_title" }, [
          _vm._v("请选择角色")
        ]),
        _c(
          "div",
          { staticClass: "register_role_wrapper" },
          _vm._l(_vm.roles, function(item) {
            return _c(
              "div",
              {
                key: item.id,
                class:
                  _vm.form.identity === item.id
                    ? "register_role_item is_active"
                    : "register_role_item",
                on: {
                  click: function($event) {
                    return _vm.handleChangeRole(item)
                  }
                }
              },
              [_vm._v(" " + _vm._s(item.label) + " ")]
            )
          }),
          0
        )
      ]),
      _c(
        "el-form-item",
        { staticClass: "mobile", attrs: { prop: "mobile" } },
        [
          _c(
            "el-input",
            {
              attrs: { placeholder: "请输入手机号码" },
              model: {
                value: _vm.form.mobile,
                callback: function($$v) {
                  _vm.$set(_vm.form, "mobile", $$v)
                },
                expression: "form.mobile"
              }
            },
            [
              _c(
                "div",
                { attrs: { slot: "prepend" }, slot: "prepend" },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.value,
                        callback: function($$v) {
                          _vm.value = $$v
                        },
                        expression: "value"
                      }
                    },
                    _vm._l(_vm.options, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { prop: "code" } },
        [
          _c(
            "el-input",
            {
              attrs: { placeholder: "请输入验证码" },
              model: {
                value: _vm.form.code,
                callback: function($$v) {
                  _vm.$set(_vm.form, "code", $$v)
                },
                expression: "form.code"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "suffix_code",
                  attrs: { slot: "suffix" },
                  on: { click: _vm.startDuration },
                  slot: "suffix"
                },
                [_vm._v(_vm._s(_vm.duration))]
              )
            ]
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { prop: "password" } },
        [
          _c("el-input", {
            attrs: {
              "show-password": "",
              placeholder: "请输入密码",
              type: "password"
            },
            model: {
              value: _vm.form.password,
              callback: function($$v) {
                _vm.$set(_vm.form, "password", $$v)
              },
              expression: "form.password"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "office" }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("el-checkbox", {
              model: {
                value: _vm.form.checked,
                callback: function($$v) {
                  _vm.$set(_vm.form, "checked", $$v)
                },
                expression: "form.checked"
              }
            })
          ],
          1
        ),
        _c("div", { staticClass: "right" }, [
          _c("div", [_vm._v("我已审慎阅读并同意")])
        ])
      ]),
      _c("div", { staticClass: "text" }, [
        _c("span", { on: { click: _vm.showPrivacyPolicy } }, [
          _vm._v("《隐私协议》")
        ])
      ]),
      _c(
        "div",
        { staticClass: "btn", on: { click: _vm.throttledHandleRegister } },
        [_vm._v("注册")]
      ),
      _c("div", { staticClass: "info", on: { click: _vm.handleChangeMode } }, [
        _c("span", [_vm._v("已有账户，")]),
        _c("span", [_vm._v("去登录")])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }