<template>
  <footer class="layout-footer">
    <div class="container">
      <div class="footer_top">
        <div class="footer_left">
          <div class="footer_logo">
            <img src="@/assets/images/bottomLogo.png" alt="" />
          </div>
          <div class="footer_us">
            <span class="cursor" @click="aboutUs">关于我们</span>
            <span class="cursor" @click="contactUs">联系我们</span>
          </div>
        </div>
        <div class="footer_right">
          <div class="footer_right_qrcode">
            <div class="footer_r_q">
              <!-- <div class="footer_gzh" >
                <img src="@/assets/images/gzh.png" alt="" />
                <span>公众号</span>
              </div> -->
              <div class="footer_xcx">
                <img src="@/assets/images/bottomLogo1.jpg" alt="" />
                <span>公众号</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer_bottom">
        Copyright ©2024 www.airflydream.com 北京优百惠科技有限公司 版权所有 | <span class="cursor" @click="gobeian">京ICP备19019961号-2</span
        ><span class="cursor wangan" @click="gowangan">京公网安备11010202010893</span>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'layout-footer',
  data() {
    return {};
  },
  methods: {
    gobeian() {
      window.open('https://beian.miit.gov.cn/#/Integrated/inde');
    },
    gowangan() {
      window.open('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010202010893');
    },
    contactUs() {
      this.$router.push({
        path: '/contactUs',
      });
    },
    aboutUs() {
      this.$router.push({
        path: '/aboutUs',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-footer {
  width: 100%;
  .container {
    width: $base_width;
    height: 100%;
    margin: auto;
    display: flex;
    padding: 60px 0 30px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .footer_top {
      display: flex;
      justify-content: space-between;
      .footer_right,
      .footer_left {
        height: 151px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .footer_right_qrcode {
          width: 100%;

          height: 100%;
          .footer_r_q {
            display: flex;
            justify-content: space-between;
            height: 100%;
            .footer_gzh,
            .footer_xcx {
              margin-left: 215px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;
              img {
                width: 116px;
                height: 116px;
              }
              span {
                height: 21px;
                font-family: MicrosoftYaHei;
                font-size: 16px;
                color: #aeb5bf;
                line-height: 21px;
                font-style: normal;
              }
            }
          }
        }
      }

      .footer_right {
        width: 332px;
      }
      .footer_left {
        width: 141px;
        .footer_logo {
          width: 116px;
          height: 116px;
          margin: auto;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .footer_us {
          margin-top: 14px;
          display: flex;
          justify-content: space-between;
          height: 21px;
          font-family: MicrosoftYaHei;
          font-size: 16px;
          color: #aeb5bf;
          line-height: 21px;
          font-style: normal;
        }
      }
    }
    .footer_bottom {
      height: 19px;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #aeb5bf;
      line-height: 19px;
      font-style: normal;
      text-align: center;
      .wangan {
        padding-left: 15px;
      }
    }
  }
}
</style>
