var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "fly_rank_wrapper pointer" }, [
    _c("div", { staticClass: "swiper swiperFlightRanking" }, [
      _c(
        "div",
        { staticClass: "swiper-wrapper" },
        _vm._l(_vm.list, function(itemChild) {
          return _c(
            "div",
            {
              key: itemChild,
              staticClass: "swiper-slide fly_rank_carousel_item_child"
            },
            [
              _c("div", { staticClass: "fly_rank_carousel_item_child_title" }, [
                _c("div", { staticClass: "title_name" }, [
                  _vm._v(_vm._s(itemChild.flightItem))
                ])
              ]),
              _c(
                "ul",
                { staticClass: "clearfix" },
                _vm._l(itemChild.rankingList, function(item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass: "fly-list",
                      style: {
                        "background-image": `url(${_vm.getImageUrl(index)})`
                      }
                    },
                    [
                      _c("div", { staticClass: "text-content" }, [
                        _c("div", { staticClass: "left-content" }, [
                          _c(
                            "div",
                            {
                              staticClass: "text pointer",
                              on: {
                                click: function($event) {
                                  return _vm.handlePush(item)
                                }
                              }
                            },
                            [_vm._v(_vm._s(item.name))]
                          ),
                          _c("div", { staticClass: "teamsName" }, [
                            _vm._v(_vm._s(item.teamsName))
                          ])
                        ]),
                        _c("div", { staticClass: "right-content" }, [
                          _c("div", { staticClass: "result" }, [
                            _vm._v(" " + _vm._s(item.result) + " ")
                          ])
                        ])
                      ])
                    ]
                  )
                }),
                0
              )
            ]
          )
        }),
        0
      ),
      _c("div", { staticClass: "swiper-pagination" })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }