<template>
  <session :class="isSilder ? 'layout-session top' : 'layout-session'">
    <div class="container">
      <keep-alive>
        <router-view :key="$route.fullPath" v-if="!isSilder"></router-view>
        <div class="has-slider" v-else>
          <div class="body"></div>
          <SliderNav />
          <router-view :key="$route.fullPath" style="float: left"></router-view>
        </div>
      </keep-alive>
    </div>
    <suspension></suspension>
  </session>
</template>

<script>
import suspension from '@/components/suspension/index.vue';
import { mapGetters } from 'vuex';
import SliderNav from './slider-nav.vue';
import { ROUTESILDE } from '@/config/router-level';
export default {
  name: 'layout-session',
  components: { SliderNav, suspension },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['topRouters', 'topChildSlidRouters', 'isSilder', 'activeIndex']),
  },
  watch: {
    $route(to) {
      // this.activeIndex = to.name;
      console.log(to);

      if (this.topChildSlidRouters[to.name] && this.topChildSlidRouters[to.name].length > 0) {
        this.$store.commit('permission/SET_SIDEBAR_ROUTERS', true);
        this.$store.commit('permission/SET_TOP_NAME', to.name);
        this.$store.commit('permission/SET_TOP_PATH', to.fullPath);
      } else {
        if (to.meta.level === ROUTESILDE) {
          this.$store.commit('permission/SET_SIDEBAR_ROUTERS', true);
        } else {
          this.$store.commit('permission/SET_SIDEBAR_ROUTERS', false);
        }
      }
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.layout-session {
  width: 100%;
  &:after {
    content: '';

    display: block;

    clear: both;
  }
  .container {
    width: $base_width;
    height: 100%;
    margin: auto;
    &:after {
      content: '';

      display: block;

      clear: both;
    }
    .body {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 70px;
      background: #0a62d4;
      z-index: 12;
    }
  }
  .has-slider {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    flex: 1;
    display: flex;
  }
}
.top {
  .container {
    margin-top: 70px;
    min-height: calc(100vh - 40px);
    position: relative;
    display: flex;
  }
}
</style>
