<template>
  <div class="fly_rank_wrapper pointer">
    <div class="swiper swiperFlightRanking">
      <div class="swiper-wrapper">
        <div class="swiper-slide fly_rank_carousel_item_child" v-for="itemChild in list" :key="itemChild">
          <div class="fly_rank_carousel_item_child_title">
            <div class="title_name">{{ itemChild.flightItem }}</div>
          </div>
          <ul class="clearfix">
            <li
              class="fly-list"
              :style="{ 'background-image': `url(${getImageUrl(index)})` }"
              v-for="(item, index) in itemChild.rankingList"
              :key="index"
            >
              <div class="text-content">
                <div class="left-content">
                  <div class="text pointer" @click="handlePush(item)">{{ item.name }}</div>
                  <div class="teamsName">{{ item.teamsName }}</div>
                </div>
                <div class="right-content">
                  <div class="result">
                    {{ item.result }}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- 如果需要分页器 -->
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>
<script>
import { flightList } from '@/api/index';
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';
import 'swiper/swiper-bundle';
export default {
  name: 'FlightRankingSwiper',
  data() {
    return {
      list: [],
      activeIndex: 0,
      mySwiper: null,
    };
  },
  mounted() {
    this.getflightList();
    window.addEventListener('popstate', this.initWrapper);
    this.initWrapper();
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.initWrapper);
  },
  methods: {
    getImageUrl(index) {
      // 根据索引生成图像URL
      return require(`@/assets/images/rankingist_BK0${index + 1}.png`);
    },
    handleChange(e) {
      this.activeIndex = e;
    },
    handlePush(item) {
      this.$router.push({
        path: '/flightRankingDetail',
        query: {
          id: item.id,
        },
      });
    },
    initWrapper() {
      setTimeout(() => {
        this.mySwiper = new Swiper('.swiperFlightRanking', {
          loop: this.list.length > 3 ? true : false, // 循环模式选项
          slidesPerView: 3,
          spaceBetween: 35,
          autoplay: {
            delay: 2500,
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          on: {
            beforeLoopFix: function() {
              // 如果滑块不够
              if (this.slides.length < 3) {
                // 禁止循环修复
                this.loopedSlides = 0;
                this.loopDestroy();
              }
            },
          },
        });
      }, 400);
    },
    async getflightList() {
      let result = await flightList();
      this.list = result.data;
    },
  },
};
</script>
<style lang="scss">
.fly_rank_wrapper {
  width: 100%;
  height: 100%;
  .swiper-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
  }
  .swiper-pagination {
    /* 如果需要，可以添加对齐方式，例如对齐到父容器的底部 */
    position: absolute;
    /* 这里的父容器需要是相对定位或绝对定位 */
    z-index: 1; /* 确保分页器在所有内容之上 */
  }
  .fly_rank_carousel_item_child {
    width: 370px;
    height: 100%;
    padding-left: 20px;
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    &:nth-last-child(1) {
      padding-right: 0;
    }
    .fly_rank_carousel_item_child_title {
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 23px;
      .title_name {
        width: 370px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 24px;
        font-weight: bold;
        font-size: 18px;
        color: #333333;
        line-height: 24px;
        text-align: center;
        font-style: normal;
        margin-top: 12px;
      }
    }
  }
  .fly-list {
    width: 370px;
    height: 64px;
    border-radius: 4px;
    background-size: 100% 64px;
    display: flex;
    align-items: center;
    float: left;
    margin-right: 20px;
    margin-bottom: 6px;
    &:nth-last-child(1) {
      margin-bottom: 40px;
    }
    .text-content {
      display: flex;
      height: 100%;
      width: 100%;
      padding-left: 74px;
      padding-top: 18px;
      justify-content: space-between;
      .left-content {
        .text {
          font-weight: bold;
          font-size: 16px;
          color: #333333;
        }
        .teamsName {
          height: 18px;
          font-size: 14px;
          margin-top: 2px;
          color: #7d7f80;
          line-height: 18px;
          text-align: left;
          font-style: normal;
        }
      }
      .right-content {
        padding: 10px 40px 0 0;
        .result {
          width: 41px;
          height: 27px;
          font-family: ArialMT;
          font-size: 24px;
          color: #000000;
          line-height: 28px;
          text-align: right;
          font-style: normal;
        }
      }
    }
  }
}
</style>
