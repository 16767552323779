<template>
  <div style="float: left">
    <div v-if="item.children && item.children.length > 0">
      <el-submenu popper-append-to-body :index="item.name">
        <template slot="title">{{ item.meta.title }}</template>
        <menu-item
          v-for="(child, index) in item.children"
          :item="child"
          :key="child.path + index"
          :base-path="resolvePath(child.path, child, 'sub')"
          :index="child.name"
        />
      </el-submenu>
    </div>
    <AppLink v-else style="float: left" :to="resolvePath(item.path, item, 'one')">
      <el-menu-item :index="item.name">{{ item.meta.title }}</el-menu-item>
    </AppLink>
  </div>
</template>

<script>
import AppLink from './Link.vue';
import path from 'path';
import { isExternal } from '@/utils/validate';

import { ROUTESILDE } from '@/config/router-level';
import { mapGetters } from 'vuex';
export default {
  name: 'menu-item',
  components: { AppLink },
  props: {
    // route object
    type: {
      type: Object,
    },
    item: {
      type: Object,
      required: true,
    },
    basePath: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters(['topRouters', 'topChildSlidRouters', 'isSilder', 'topName', 'topPath']),
  },
  methods: {
    resolvePath(routePath, route, type) {
      if (isExternal(routePath)) {
        return routePath;
      }
      if (isExternal(this.basePath)) {
        return this.basePath;
      }
      if (route.meta.level !== ROUTESILDE) {
        if (type === 'sub') {
          return path.resolve(this.basePath, routePath);
        } else {
          return this.basePath;
        }
      } else {
        if (type === 'one') {
          return path.resolve(this.topPath, this.basePath || routePath);
        } else {
          return path.resolve(this.topPath, this.basePath, routePath);
        }
      }
    },
  },
};
</script>
