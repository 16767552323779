import Vue from 'vue';
import VueRouter from 'vue-router';
import NProgress from 'nprogress'; // 进度条
import 'nprogress/nprogress.css';
import store from '@/store/index';

import { ROUTESILDE } from '@/config/router-level';
import routes, { coach, person, team } from './routes';
import { Message } from 'element-ui';

const routesDeep = Array.from(routes);
const _import = require('@/utils/util.import');
Vue.use(VueRouter);

// 解决重复点击菜单报错 Uncaught (in promise) NavigationDuplicated{_name: "NavigationDuplicated", name: "NavigationDuplicated"}
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

// 导出路由 在 main.js 里使用
const router = new VueRouter({
  routes,
  scrollBehavior() {
    //进入下一个页面后，上下滚动条默认置顶
    document.querySelector('.ui-theme-container-main') && (document.querySelector('.ui-theme-container-main').scrollTop = 0);
    return {
      x: 0,
      y: 0,
    };
  },
});
let hasAddedRoutes = false;
/**
 * 路由拦截
 * 权限验证
 */
router.beforeEach((to, from, next) => {
  // 进度条
  NProgress.start();
  if (to.meta && to.meta.title) {
    document.title = '模拟飞行';
  }
  if (to.name === '404' || to.name === '500' || to.meta.skipAuth) {
    return next();
  }
  /* 这里暂时将cookie里是否存有token作为验证是否登录的条件,请根据自身业务需要修改*/
  const token = localStorage.getItem('web-token');
  const identity = localStorage.getItem('identity') - 0;

  // const menu = store.state.admin.common.header;
  if (!token && to.meta.level === ROUTESILDE) {
    /* 没有登录的时候跳转到登录界面, 携带上登陆成功之后需要跳转的页面完整路径*/
    next({
      name: 'login',
      query: {
        redirect: to.fullPath,
      },
    });
  } else {
    const dyncRoute = localStorage.getItem('dyncRoute'); // 动态路由标志
    const isAdd = router.options.routes[0].children.find((f) => f.name === dyncRoute);
    if (token && !isAdd && to.name !== 'login' && identity) {
      reloadDynamicRoutes(hasAddedRoutes).then(() => {
        return next({ ...to, replace: true }); // 使用 replace: true 选项，以替换当前路由历史记录
      });
    }
  }

  /* 这里暂时将cookie里是否存有token作为验证是否登录的条件,请根据自身业务需要修改*/
  // const token = localStorage.getItem('token');
  // const menu = store.state.admin.common.header;
  // if (!token) {
  //   /* 没有登录的时候跳转到登录界面, 携带上登陆成功之后需要跳转的页面完整路径*/
  //   next({
  //     name: 'login',
  //     query: {
  //       redirect: to.fullPath,
  //     },
  //   });
  // } else {
  if (to.meta.redirect) {
    setTimeout(() => {
      next(to.meta.redirect);
    }, 200);
  }
  const dyncRoute = localStorage.getItem('dyncRoute'); // 动态路由标志
  const names = {
    [1]: 'nickName',
    [2]: 'nickName',
    [3]: 'teamsName',
  };
  if (dyncRoute) {
    if (!JSON.parse(localStorage.getItem('userInfo'))[names[identity]] && to.meta.level === ROUTESILDE && !to.meta.noneed) {
      Message.warning('请先完善个人信息');
      next(false);
      NProgress.done();
    } else {
      next();
    }
  } else {
    next(); //
  }
  // }
});

router.afterEach(() => {
  // 进度条
  NProgress.done();
});
export function resetRouter() {
  // routes.pop()

  const newRouter = new VueRouter({
    routes: routesDeep,
    scrollBehavior() {
      //进入下一个页面后，上下滚动条默认置顶
      document.querySelector('.ui-theme-container-main') && (document.querySelector('.ui-theme-container-main').scrollTop = 0);
      return {
        x: 0,
        y: 0,
      };
    },
  });
  router.matcher = new VueRouter(newRouter.options).matcher;
}
function reloadDynamicRoutes(hasAddedRoutes) {
  // 这里模拟重新加载动态路由的异步操作
  const identity = localStorage.getItem('identity') - 0;
  return new Promise((resolve) => {
    if (identity === 1) {
      if (!hasAddedRoutes) {
        setTimeout(() => {
          resetRouter();
          router.addRoute({
            path: '*',
            name: '404',
            component: _import('system/error/404'),
          });
          router.addRoute('home', person);
          let s = router.options.routes[0].children.find((f) => f.name === person.name);
          if (!s) {
            router.options.routes[0].children.push(person);
          } else {
            router.options.routes[0].children.pop();
            router.options.routes[0].children.push(person);
          }
          localStorage.setItem('dyncRoute', person.name);
          // 设置标志位为 true，表示已添加路由
          hasAddedRoutes = true;
          store.commit('permission/SET_TOP_ROUTERS', [router.options.routes[0]]);
          // 手动重新导航到当前路由
          resolve();
          // return next({ ...to, replace: true }); // 使用 replace: true 选项，以替换当前路由历史记录
        }, 10);
      }
    } else if (identity === 3) {
      if (!hasAddedRoutes) {
        setTimeout(() => {
          resetRouter();
          router.addRoute({
            path: '*',
            name: '404',
            component: _import('system/error/404'),
          });
          router.addRoute('home', team);
          let s = router.options.routes[0].children.find((f) => f.name === team.name);
          if (!s) {
            router.options.routes[0].children.push(team);
          } else {
            router.options.routes[0].children.pop();
            router.options.routes[0].children.push(team);
          }
          localStorage.setItem('dyncRoute', person.name);
          // 设置标志位为 true，表示已添加路由
          hasAddedRoutes = true;
          store.commit('permission/SET_TOP_ROUTERS', [router.options.routes[0]]);
          // 手动重新导航到当前路由

          resolve();
          // return next({ ...to, replace: true }); // 使用 replace: true 选项，以替换当前路由历史记录
        }, 10);
      }
    } else if (identity === 2) {
      if (!hasAddedRoutes) {
        setTimeout(() => {
          resetRouter();
          router.addRoute({
            path: '*',
            name: '404',
            component: _import('system/error/404'),
          });
          router.addRoute('home', coach);
          let s = router.options.routes[0].children.find((f) => f.name === coach.name);
          if (!s) {
            router.options.routes[0].children.push(coach);
          } else {
            router.options.routes[0].children.pop();
            router.options.routes[0].children.push(coach);
          }

          localStorage.setItem('dyncRoute', person.name);
          // 设置标志位为 true，表示已添加路由
          hasAddedRoutes = true;
          store.commit('permission/SET_TOP_ROUTERS', [router.options.routes[0]]);
          // 手动重新导航到当前路由

          resolve();
          // return next({ ...to, replace: true }); // 使用 replace: true 选项，以替换当前路由历史记录
        }, 10);
      }
    }
  });
}
export default router;
