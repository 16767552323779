import confirmApiList from '../config/confirm-apis';
import axios from 'axios';
// import { sessionCache } from '@csii/vx-util';

const preventTokenObj = {};

function refreshToken(apiKey) {
  // 使用原生axios是为了避免和remote循环引用
  return axios
    .post('/pweb/getToken.do')
    .then((resp) => {
      preventTokenObj[apiKey] = resp.data.data._tokenName;
      // sessionCache.setItem('token', resp.data.data._tokenName);
    })
    .catch((err) => {
      console.log(err);
    });
}

function resolveToken(apiKey) {
  const token = preventTokenObj[apiKey];
  if (!token) {
    return Promise.reject('该接口需要token, token不存在');
  } else {
    return token;
  }
}

function isConfirmApi(apiUrl) {
  const pureUrl = apiUrl.replace(/\?.*$/, '');
  return confirmApiList.find((temp) => pureUrl.endsWith(temp));
}

export default { refreshToken, resolveToken, isConfirmApi };
