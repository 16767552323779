<template>
  <div class="slider_wrapper">
    <el-menu class="slider_menu" :default-openeds="defaultOpeneds" :default-active="activeIndexSlider" @select="handleSelect">
      <MenuItem type="slider" :base-path="item.path" v-for="(item, index) in topChildSlidRouters[topName]" :item="item" :key="item.path + index" />
    </el-menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MenuItem from './menuItem.vue';
import { ROUTESILDE } from '@/config/router-level';
export default {
  name: 'slider-nav',
  components: { MenuItem },
  data() {
    return {
      defaultOpeneds: ['person', 'game', 'account', 'team', 'teacher', 'coach'],
    };
  },
  computed: {
    ...mapGetters(['topRouters', 'topChildSlidRouters', 'isSilder', 'topName', 'activeIndexSlider']),
  },
  watch: {
    $route(to) {
      // this.activeIndex = to.name;
      if (this.topChildSlidRouters[to.name] && this.topChildSlidRouters[to.name].length > 0) {
        this.$store.commit('permission/SET_SIDEBAR_ROUTERS', true);
        this.$store.commit('permission/SET_TOP_NAME', to.name);
        this.$store.commit('permission/SET_TOP_PATH', to.fullPath);
      } else {
        if (to.meta.level === ROUTESILDE) {
          this.$store.commit('permission/SET_SIDEBAR_ROUTERS', true);
          this.$store.commit('permission/SET_SLIDER_ACTIVE', to.name);
        } else {
          this.$store.commit('permission/SET_SIDEBAR_ROUTERS', false);
        }
      }
    },
  },
  methods: {
    handleSelect(e) {
      console.log(e);
      //
      this.$store.commit('permission/SET_SLIDER_ACTIVE', e);
    },
  },
};
</script>
<style lang="scss">
.slider_wrapper {
  height: 100%;
  float: left;
  box-shadow: inset -4px 0 11px 0px rgba(62, 78, 95, 0.1);
  .slider_menu {
    height: 100%;
    width: $base_menu_slider_width;

    background: #f5faff;
    & > div {
      width: 100%;
    }
    .el-submenu .el-menu-item {
      min-width: 100%;
      box-sizing: border-box;
    }
  }

  .el-menu {
    div,
    a {
      width: 100%;
    }
  }

  .is-active {
    width: 100%;
    box-sizing: border-box;
  }
}
</style>
