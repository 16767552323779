var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "nodata" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.list && _vm.list.length > 0,
            expression: "list && list.length > 0"
          }
        ]
      },
      [_vm._t("default")],
      2
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.list == null || (_vm.list && _vm.list.length === 0),
            expression: "list == null || (list && list.length === 0)"
          }
        ],
        staticClass: "list-no-data",
        style: { "--heightLine": _vm.height + "px" }
      },
      [_vm._m(0)]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/icon_nodata@2x.png"),
          alt: "暂无数据"
        }
      }),
      _c("div", { staticClass: "no-text" }, [_vm._v("暂无数据")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }