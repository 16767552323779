<template>
  <div class="login">
    <div class="bk_wrapper">
      <div class="logo"></div>
    </div>
    <div class="login_wrapper">
      <div class="form_wrapper">
        <LoginForm v-if="mode === 'login'" @changeMode="changeLoginMode" @resetMode="mode = 'resetPassWord'" />
        <ResetPassWord v-else-if="mode === 'resetPassWord'" @changeMode="mode = 'login'"></ResetPassWord>
        <RegisterForm v-else @changeMode="mode = 'login'" :mobile="mobile" @changePrivacyPolicy="privacyPolicy = true" />
      </div>
    </div>
    <div class="dialog-box">
      <el-dialog title="隐私协议" :visible.sync="privacyPolicy" width="50%" modal="false" custom-class="dialog-height" center>
        <PrivacyPolicy />
        <div class="privacyPolicyBtn">
          <el-button type="primary" @click="privacyPolicy = false" size="mini">我已知晓并同意</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import LoginForm from './loginForm.vue';
import RegisterForm from './register.vue';
import ResetPassWord from './resetPassWord.vue';
import PrivacyPolicy from '@/components/privacyPolicy';
export default {
  name: 'login',
  components: { LoginForm, ResetPassWord, RegisterForm, PrivacyPolicy },
  data() {
    return {
      mode: 'login', // login regisiter
      mobile: '',
      privacyPolicy: false,
    };
  },
  mounted() {
    this.$store.commit('permission/SET_SIDEBAR_ROUTERS', false);
    this.mode = this.$router.currentRoute.params.type ? this.$router.currentRoute.params.type : 'login';
    // sessionStorage.removeItem('menu');
    // localStorage.removeItem('identity');
    // localStorage.removeItem('dyncRoute');
    // localStorage.removeItem('userInfo');
  },
  methods: {
    changeLoginMode(data) {
      this.mode = 'regisiter';
      if (data) {
        this.mobile = data;
      } else {
        this.mobile = '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100%;
  float: left;
  display: flex;
  background: url('~@/assets/images/login_bk_big.png') no-repeat center;

  .bk_wrapper {
    width: calc(100% - 440px);
    height: 100%;
    position: relative;
    .logo {
      width: 152px;
      height: 60px;
      background: url('~@/assets/images/logo.png') no-repeat center;
      background-size: contain;
      margin: 60px 0 0 60px;
      float: left;
    }
  }
  .login_wrapper {
    width: 440px;
    height: 100%;
    // margin: 188px auto;
    // background: url('~@/assets/images/login_pop_bk.png') no-repeat center;
    // background-size: contain;
    display: table;
    .form_wrapper {
      width: 100%;
      height: 100%;
      float: right;
      // padding: 22px 54px 44px 0;
      box-sizing: border-box;
      background: linear-gradient(180deg, #fcfdfe 0%, #dee3e9 100%);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .dialog-box {
    //弹出层的高度
    ::v-deep .el-dialog.dialog-height {
      height: auto;
      max-height: 80vh;
      overflow-y: auto;
    }
    //弹出层里内容的高度
    ::v-deep .el-dialog__body {
      max-height: 70vh !important;
    }
    .privacyPolicyBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 20px;
    }
  }
}
</style>
