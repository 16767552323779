<template>
  <div class="avaiation_wrapper">
    <div class="download_center">
      <div class="download_title ">
        <div>下载中心</div>
        <div class="icon_arrow pointer" @click="handlePushDown()">
          <img src="@/assets/images/icon_arrow.png" alt="" />
        </div>
      </div>
      <div class="download_list">
        <div class="download_item" v-for="item in list" :key="item.id">
          <div class="download_item_title">{{ item.appName }}</div>
          <div class="download_item_icon pointer" @click="downloadFile(item)"></div>
        </div>
      </div>
    </div>
    <div class="avaiation_center">
      <div class="avaiation_title">资讯中心</div>
      <div class="avaiation_content">
        <div class="avaiation_content_bg">
          <div class="bg"></div>
          <div class="bg"></div>
        </div>
        <div class="avaiation_content_list">
          <div class="avaition_content_item" @click="handlePush(item)" v-for="item in aviationList" :key="item.id">
            <div class="avaition_content_title">{{ item.title }}</div>
            <div class="avaition_content_time">{{ item.publishDate }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import downPageApi from '@/api/downPage.js';
import { downloadList, newsListFour } from '@/api/index.js';
export default {
  name: 'AvaiationConsulting',
  data() {
    return {
      list: [],
      aviationList: [],
    };
  },
  mounted() {
    this.getDownLoadList();
    this.getNewsList();
  },
  methods: {
    async getDownLoadList() {
      let result = await downloadList({
        pageNo: 1,
        pageSize: 4,
      });
      this.list = result.data.items;
      console.log(this.list, 'dsadasdas');
    },
    async getNewsList() {
      let result = await newsListFour({
        pageNo: 1,
        pageSize: 4,
      });
      this.aviationList = result.data;
    },
    handlePush(item) {
      this.$router.push({
        path: '/aviationcompetitionDetail',
        query: {
          id: item.id,
        },
      });
    },
    handlePushDown() {
      this.$router.push({
        path: '/downPage',
      });
    },
    async downloadFile(item) {
      console.log(item, 'itemitem');
      let res = await downPageApi.downloadFile({
        fileName: item.fujian.split('templateUpload/')[1],
      });
      const blob = new Blob([res]);
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = item.fujian.split('templateUpload/')[1];
      link.click();
    },
  },
};
</script>
<style lang="scss" scoped>
.avaiation_wrapper {
  width: 100%;
  height: 410px;
  display: flex;
  border-radius: 10px;
  background: linear-gradient(180deg, #eeeeee 0%, #e5e5e5 100%);
  .download_center {
    width: 420px;
    height: 100%;
    background: linear-gradient(180deg, #9aa2b0 0%, #626e7e 100%);
    padding: 38px 38px 63px 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .download_title {
      width: 100%;
      height: 26px;
      font-family: MicrosoftYaHei;
      font-size: 20px;
      color: #ffffff;
      line-height: 26px;
      text-align: center;
      display: flex;
      justify-content: space-between;
      font-style: normal;
    }
    .download_list {
      flex: 1;
      margin-top: 3px;

      .download_item {
        width: 100%;
        height: 38px;
        display: flex;
        justify-content: space-between;
        margin-top: 34px;
        .download_item_title {
          height: 20px;
          font-family: MicrosoftYaHei;
          font-size: 14px;
          color: #ffffff;
          line-height: 20px;
          text-align: left;
          font-style: normal;
          padding-left: 52px;
          box-sizing: border-box;
          position: relative;
          &:before {
            content: '';
            width: 36px;
            height: 36px;
            background: url('~@/assets/images/icon_file.png') no-repeat center;
            background-size: contain;
            position: absolute;
            left: 0;
            top: 0;
          }
        }
        .download_item_icon {
          min-width: 28px;
          height: 28px;
          align-self: end;
          margin-left: 16px;
          background: url('~@/assets/images/icon_download.png') no-repeat center;
          background-size: contain;
        }
      }
    }
  }
  .avaiation_center {
    flex: 1;
    height: 100%;
    padding: 38px 84px 41px 84px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .avaiation_title {
      width: 100%;
      height: 26px;
      font-family: MicrosoftYaHei;
      font-size: 20px;
      color: #333333;
      line-height: 26px;
      text-align: center;
      font-style: normal;
      margin-bottom: 36px;
    }
    .avaiation_content {
      flex: 1;
      display: flex;
      .avaiation_content_bg {
        width: 232px;
        height: 100%;
        display: flex;
        flex-direction: column;
        .bg {
          width: 100%;
          height: 130px;
          background: url('~@/assets/images/pic01.png') no-repeat center;

          &:nth-child(2) {
            background: url('~@/assets/images/pic02.png') no-repeat center;
          }
          background-size: cover;
        }
      }
      .avaiation_content_list {
        flex: 1;
        margin-left: 22px;
        cursor: pointer;
        .avaition_content_item {
          width: 100%;
          padding-left: 9px;
          box-sizing: border-box;
          .avaition_content_title {
            min-height: 19px;
            font-family: MicrosoftYaHei;
            font-size: 14px;
            color: #333333;
            line-height: 19px;
            text-align: left;
            font-style: normal;
            position: relative;
            &:before {
              content: '·';
              position: absolute;
              top: 0;
              bottom: 0;
              left: -9px;
              margin: auto;
            }
            margin-bottom: 4px;
          }
          .avaition_content_time {
            height: 19px;
            font-family: MicrosoftYaHei;
            font-size: 14px;
            color: rgba(51, 51, 51, 0.35);
            line-height: 19px;
            text-align: left;
            font-style: normal;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}
</style>
