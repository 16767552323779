<template>
  <nav class="layout_nav">
    <el-menu :default-active="activeIndex" class="el-menu-nav" mode="horizontal" @select="handleSelect">
      <MenuItem :type="'nav'" v-for="(item, index) in topRouters" :item="item" :base-path="item.path" :key="item.path + index" />
    </el-menu>
  </nav>
</template>
<script>
import { mapGetters } from 'vuex';
import { ROUTESILDE } from '@/config/router-level';
import MenuItem from './menuItem.vue';
export default {
  name: 'layout_nav',

  components: { MenuItem },
  data() {
    return {};
  },

  computed: {
    ...mapGetters(['topRouters', 'topChildSlidRouters', 'activeIndex']),
  },
  watch: {
    $route(to) {
      this.$store.commit('permission/SET_TOP_ACTIVE', to.name);
      if (this.topChildSlidRouters[to.name] && this.topChildSlidRouters[to.name].length > 0) {
        this.$store.commit('permission/SET_SIDEBAR_ROUTERS', true);
      } else {
        if (to.meta.level === ROUTESILDE) {
          this.$store.commit('permission/SET_SIDEBAR_ROUTERS', true);
        } else {
          this.$store.commit('permission/SET_SIDEBAR_ROUTERS', false);
        }
      }
    },
  },
  methods: {
    handleSelect(e) {
      // this.activeIndex = e;
      this.$store.commit('permission/SET_TOP_ACTIVE', e);
    },
  },
};
</script>

<style lang="scss">
.layout_nav {
  float: left;
  height: $base_layout_height;
  margin-left: 60px;

  .el-submenu__title {
    height: 100%;
    line-height: $base_layout_height;
    margin: 0 24px;
    padding: 0;
    .el-submenu__icon-arrow {
      right: auto;
    }
    &:hover {
      background-color: transparent;
      color: $base_menu_text_active_color;
    }
    &:focus {
      background-color: transparent;
    }
    color: $base_menu_text_color;
  }

  .el-menu-nav {
    height: 100%;
    background-color: transparent;
    .el-menu-item,
    .el-submenu {
      height: $base_layout_height;
      line-height: $base_layout_height;
    }
    .el-menu-item {
      margin: 0 24px;
      padding: 0;
      font-family: MicrosoftYaHei, MicrosoftYaHei;
      font-weight: bold;
      font-size: 14px;
      color: $base_menu_text_color !important;
      font-style: normal;
      &:hover {
        background-color: transparent;
        color: $base_menu_text_active_color;
      }
      &:focus {
        background-color: transparent;
      }
    }
    .is-active {
      color: $base_menu_text_active_color !important;
    }
  }
}
.el-menu--horizontal > .el-submenu .el-submenu__title {
  height: $base_layout_height !important;
  line-height: $base_layout_height !important;
}
</style>
