var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class:
        _vm.type === "dark" ? "page_card_wrapper_dark" : "page_card_wrapper"
    },
    [
      _c("div", { staticClass: "page_card_title" }, [
        _vm._v(_vm._s(_vm.title))
      ]),
      _vm._t("desc"),
      _vm._t("content")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }