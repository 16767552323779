import Vue from 'vue';
import locale from '@/config/locale';
import tokenSvc from '@/services/token';
import storage from '@/utils/storage';

Vue.mixin({
  name: 'mixin',
  methods: {
    ...storage,
    // 相当于定义全局方法
    $field: function(key) {
      return locale.FIELDS[key];
    },
    $msg: function(key) {
      return locale.MESSAGES[key];
    },
    // 获得防重复提交token
    getToken: function(apiKey) {
      return tokenSvc.refreshToken(apiKey);
    },
    // 表单校验未通过时，页面回滚到第一个未通过表单校验的地方
    isNoValid() {
      this.$nextTick(() => {
        document
          .querySelectorAll('.is-error')[0]
          .querySelector('input')
          .focus();
      });
    },
  },
});
