import remote from '@/services/remote';

export const getregistrationList = async (payload) => {
  try {
    let result = await remote.get('/racetopic/list', {
      payload,
    });
    return result;
  } catch (error) {
    console.warn(error);
  }
};

// /api/info/list 赛事信息列表

export const raceList = async (payload) => {
  try {
    let result = await remote.post('/race/item-list', {
      payload,
    });
    return result;
  } catch (error) {
    console.warn(error);
  }
};
//主页6条赛事信息
export const indexList = async (payload) => {
  try {
    let result = await remote.get('/info/list-home-six', {
      payload,
    });
    return result;
  } catch (error) {
    console.warn(error);
  }
};

// /race/{raceId} 赛事详细信息

export const raceInfo = async (raceId) => {
  try {
    let result = await remote.get(`/race/${raceId}`);
    return result;
  } catch (error) {
    console.warn(error);
  }
};

// 下载列表
export const downloadList = async (payload) => {
  try {
    let result = await remote.get('/download/list', {
      payload,
    });
    return result;
  } catch (error) {
    console.warn(error);
  }
};

// /api/news/list
export const newsList = async (payload) => {
  try {
    let result = await remote.get('/news/list', {
      payload,
    });
    return result;
  } catch (error) {
    console.warn(error);
  }
};
export const newsListFour = async (payload) => {
  try {
    let result = await remote.get('/news/list-four', {
      payload,
    });
    return result;
  } catch (error) {
    console.warn(error);
  }
};

//报名界面教师接口
export const bmTeacherList = async (payload) => {
  try {
    let result = await remote.get('/user/teacher-down-list', {
      payload,
    });
    return result;
  } catch (error) {
    console.warn(error);
  }
};
// teacher/list
export const teacherList = async (payload) => {
  try {
    let result = await remote.get('/user/teacherlist', {
      payload,
    });
    return result;
  } catch (error) {
    console.warn(error);
  }
};
//飞行榜单首页6条
export const flightList = async (payload) => {
  try {
    let result = await remote.get('/race-flight/list-six', {
      payload,
    });
    return result;
  } catch (error) {
    console.warn(error);
  }
};
// /school/list
export const schoolList = async (payload) => {
  try {
    let result = await remote.get('/school/list', {
      payload,
    });
    return result;
  } catch (error) {
    console.warn(error);
  }
};

// 新增报名 registration/add
export const registrationAdd = async (payload) => {
  try {
    let result = await remote.post('/registration/add', {
      payload,
    });
    return result;
  } catch (error) {
    console.warn(error);
  }
};

// 修改报名 registration
export const registrationUpdate = async (payload) => {
  try {
    let result = await remote.put('/registration', {
      payload,
    });
    return result;
  } catch (error) {
    console.warn(error);
  }
};

// 报名信息 /registration/{id}
export const registrationInfo = async (id) => {
  try {
    let result = await remote.get(`/registration/${id}`);
    return result;
  } catch (error) {
    console.warn(error);
  }
};

// 赛事信息列表 /api/info/list

export const infoList = async (payload) => {
  try {
    let result = await remote.get('/info/list', {
      payload,
    });
    return result;
  } catch (error) {
    console.warn(error);
  }
};

// /group/grouplist 赛项分组

export const grouplist = async (payload) => {
  try {
    let result = await remote.get('/group/grouplist', {
      payload,
    });
    return result;
  } catch (error) {
    console.warn(error);
  }
};

// /wx/auth/updatePassword

export const updatePassword = async (payload) => {
  try {
    let result = await remote.post('/wx/auth/updatePassword', {
      payload,
    });
    return result;
  } catch (error) {
    console.warn(error);
  }
};
