var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.list.length < 6
      ? _c(
          "div",
          { staticClass: "school_list" },
          _vm._l(_vm.list, function(item, index) {
            return _c(
              "div",
              {
                key: item,
                staticClass: "school_wrappers",
                on: {
                  click: function($event) {
                    return _vm.handlePush(item)
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    class:
                      _vm.list.length > 3 &&
                      (index === 0 || index === _vm.list.length - 1)
                        ? "school_body_mask"
                        : ""
                  },
                  [
                    _c("div", { staticClass: "swiper_top" }, [
                      _c("img", {
                        attrs: {
                          src:
                            item.picture ||
                            require("@/assets/images/pic_03.png"),
                          alt: ""
                        }
                      })
                    ]),
                    _c("div", { staticClass: "swiper_content" }, [
                      _c("div", { staticClass: "swiper_content_title" }, [
                        _vm._v(_vm._s(item.schoolName))
                      ]),
                      _c("div", { staticClass: "swiper_content_value" }, [
                        _vm._v(" " + _vm._s(item.intro) + " ")
                      ])
                    ])
                  ]
                )
              ]
            )
          }),
          0
        )
      : _c("div", { staticClass: "school_swiper_i" }, [
          _c("div", { staticClass: "wrappers" }, [
            _c("div", { staticClass: "swiper1" }, [
              _c(
                "div",
                { staticClass: "swiper-wrapper" },
                _vm._l(_vm.list, function(item) {
                  return _c(
                    "div",
                    {
                      key: item,
                      staticClass: "swiper-slide",
                      on: {
                        click: function($event) {
                          return _vm.handlePush(item)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "body" }),
                      _c("div", { staticClass: "swiper_top" }, [
                        _c("img", {
                          attrs: {
                            src:
                              item.picture ||
                              require("@/assets/images/pic_03.png"),
                            alt: ""
                          }
                        })
                      ]),
                      _c("div", { staticClass: "swiper_content" }, [
                        _c("div", { staticClass: "swiper_content_title" }, [
                          _vm._v(_vm._s(item.schoolName))
                        ]),
                        _c("div", { staticClass: "swiper_content_value" }, [
                          _vm._v(" " + _vm._s(item.intro) + " ")
                        ])
                      ])
                    ]
                  )
                }),
                0
              ),
              _c("div", { staticClass: "swiper-pagination" })
            ])
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }