var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "event_infomation_card_wrapper" },
    _vm._l(_vm.list, function(item, index) {
      return _c("div", { key: item.id, staticClass: "event_infomation_card" }, [
        _c("div", { staticClass: "event_infomation_card_left" }, [
          _c("img", { attrs: { src: _vm.imgList[index].url, alt: "" } })
        ]),
        _c("div", { staticClass: "event_infomation_card_right" }, [
          _c(
            "div",
            {
              staticClass: "event_infomation_card_title pointer",
              on: {
                click: function($event) {
                  return _vm.goDetail(item)
                }
              }
            },
            [_vm._v(_vm._s(item.title))]
          ),
          _c("div", { staticClass: "event_infomation_card_bototm" }, [
            _c("div", { staticClass: "event_infomation_card_time" }, [
              _vm._v("报名时间")
            ]),
            _c("div", { staticClass: "event_infomation_card_time_value" }, [
              _vm._v(
                _vm._s(item.registrationBeginTime) +
                  " -- " +
                  _vm._s(item.registrationEndTime)
              )
            ]),
            _c(
              "div",
              {
                class: "event_infomation_card_btn " + _vm.handleClassets(item),
                on: {
                  click: function($event) {
                    return _vm.handlePush(item)
                  }
                }
              },
              [_vm._v(_vm._s(_vm._f("btnLabelFilter")(_vm.btnLabel, item)))]
            )
          ])
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }