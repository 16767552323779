import remote from '@/services/remote';

export const getInfo = async (id) => {
  try {
    let result = await remote.get(`/user/userDetails/${id}`);
    return result;
  } catch (error) {
    console.warn(error);
  }
};

// /user/edit
export const userEdit = async (payload) => {
  try {
    let result = await remote.put(`/user/edit`, { payload });
    return result;
  } catch (error) {
    console.warn(error);
  }
};

// 获取教育经历 by userId /api/experience/education/{userId}

export const getEducation = async (userId) => {
  try {
    let result = await remote.get(`/experience/education/${userId}`);
    return result;
  } catch (error) {
    console.warn(error);
  }
};

// 新增教育经历 /api/experience

export const experience = async (payload) => {
  try {
    let result = await remote.post(`/experience`, {
      payload,
    });
    return result;
  } catch (error) {
    console.warn(error);
    return error;
  }
};

export const experienceUpdate = async (payload) => {
  try {
    let result = await remote.put(`/experience`, {
      payload,
    });
    return result;
  } catch (error) {
    console.warn(error);
    return error;
  }
};

// 删除项目经历  /api/experience/{ids}

export const experienceDelete = async (ids) => {
  try {
    let result = await remote.delete(`/experience/${ids}`);
    return result;
  } catch (error) {
    console.warn(error);
    return error;
  }
};

// /api/experience/{id} 获取教育经历详情

export const experienceInfo = async (id) => {
  try {
    let result = await remote.get(`/experience/${id}`);
    return result;
  } catch (error) {
    console.warn(error);
    return error;
  }
};

// /api/user/reg/{userId} 报名列表

export const regList = async (payload) => {
  try {
    let result = await remote.post('/user/reg-list', {
      payload,
    });
    return result;
  } catch (error) {
    console.warn(error);
    return error;
  }
};

// /api/user/result/{userId} 成绩列表
export const resultgList = async (userId) => {
  try {
    let result = await remote.get(`/user/result/${userId}`);
    return result;
  } catch (error) {
    console.warn(error);
    return error;
  }
};
