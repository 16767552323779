var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "more_wrapper" },
    _vm._l(_vm.dataList, function(item) {
      return _c(
        "div",
        {
          key: item.id,
          staticClass: "more_item cursor",
          on: {
            click: function($event) {
              return _vm.goDetail(item.url)
            }
          }
        },
        [
          _c("div", { staticClass: "item_img" }, [
            _c("img", { attrs: { src: item.img, alt: "" } })
          ]),
          _c("div", { staticClass: "title-box" }, [
            _c("div", { staticClass: "item_title" }, [
              _vm._v(_vm._s(item.title1))
            ]),
            _c("div", { staticClass: "item_title" }, [
              _vm._v(_vm._s(item.title2))
            ])
          ])
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }