var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "banner_bar_wrapper" },
    _vm._l(_vm.bannerItemData, function(item) {
      return _c(
        "div",
        {
          key: item.id,
          staticClass: "banner_bar_item",
          style: {
            background: `url(${item.bgImg}) no-repeat center`,
            backgroundSize: "cover"
          }
        },
        [
          _c("div", { staticClass: "item_top" }, [
            _c("div", { staticClass: "item_title" }, [
              _vm._v(_vm._s(item.title))
            ]),
            _c(
              "div",
              { staticClass: "item_sub_title_wrapper" },
              _vm._l(item.subTitle, function(itemSub) {
                return _c(
                  "div",
                  {
                    key: itemSub.id,
                    class:
                      _vm.itemSubActive === itemSub.id
                        ? "item_sub_title actice"
                        : "item_sub_title",
                    on: {
                      click: function($event) {
                        _vm.itemSubActive = itemSub.id
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(itemSub.name) + " ")]
                )
              }),
              0
            )
          ]),
          _c(
            "div",
            { staticClass: "item_content" },
            _vm._l(2, function(itemList, index) {
              return _c("div", { key: index, staticClass: "item" }, [
                _c("div", { staticClass: "left" }, [
                  _vm._v("2024河北省青少年航赛")
                ]),
                _c("div", { staticClass: "right" }, [
                  _vm._v(_vm._s(_vm._f("filterLabel")(_vm.btnLabel, item.id)))
                ])
              ])
            }),
            0
          )
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }