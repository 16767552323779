<template>
  <el-form class="register_form" :model="form" :rules="rules" ref="form">
    <div class="register_title">注册账号</div>
    <div class="register_role">
      <div class="register_role_title">请选择角色</div>
      <div class="register_role_wrapper">
        <div
          :class="form.identity === item.id ? 'register_role_item is_active' : 'register_role_item'"
          @click="handleChangeRole(item)"
          v-for="item in roles"
          :key="item.id"
        >
          {{ item.label }}
        </div>
      </div>
    </div>
    <el-form-item class="mobile" prop="mobile">
      <el-input v-model="form.mobile" placeholder="请输入手机号码">
        <div slot="prepend">
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </div>
      </el-input>
    </el-form-item>
    <el-form-item prop="code">
      <el-input v-model="form.code" placeholder="请输入验证码">
        <div slot="suffix" @click="startDuration" class="suffix_code">{{ duration }}</div>
      </el-input>
    </el-form-item>
    <el-form-item prop="password">
      <el-input show-password v-model="form.password" placeholder="请输入密码" type="password"> </el-input>
    </el-form-item>
    <div class="office">
      <div class="left">
        <el-checkbox v-model="form.checked"></el-checkbox>
      </div>
      <div class="right">
        <div>我已审慎阅读并同意</div>
      </div>
    </div>
    <div class="text">
      <span @click="showPrivacyPolicy">《隐私协议》</span>
    </div>
    <div class="btn" @click="throttledHandleRegister">注册</div>
    <div class="info" @click="handleChangeMode">
      <span>已有账户，</span>
      <span>去登录</span>
    </div>
  </el-form>
</template>

<script>
import { register, login, getRegCaptcha } from '@/api/login.js';
import { Message } from 'element-ui';
import _ from 'lodash';
export default {
  name: 'RegisterForm',
  props: {
    mobile: {
      // 手机号码
      default: '',
      type: String,
    },
  },
  data() {
    return {
      roles: [
        {
          id: 1,
          label: '个人',
          value: 1,
        },
        {
          id: 2,
          label: '教练',
          value: 2,
        },
        {
          id: 3,
          label: '团体',
          value: 3,
        },
      ],
      form: {
        mobile: '',
        password: '',
        identity: 1,
        checked: true,
      },
      options: [
        {
          value: '中国+86',
          label: '中国+86',
        },
      ],
      value: '中国+86',
      privacyPolicy: false,
      durations: -1,
      rules: {
        mobile: [{ required: true, message: '请输入手机号码', trigger: 'change' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'change' }],
        password: [{ required: true, message: '请输入密码', trigger: 'change' }],
        checked: [{ required: true, message: '请先勾选隐私协议', trigger: 'change' }],
      },
      duration: '获取验证码',
      isSend: '',
      totalTime: 60,
    };
  },
  created() {
    this.initData();
  },
  methods: {
    // 初始化注册需要的数据
    async initData() {
      this.resetForm();
      this.form.mobile = this.mobile;
    },
    handleChangeMode() {
      this.$emit('changeMode');
    },
    //隐私协议
    showPrivacyPolicy() {
      this.$emit('changePrivacyPolicy');
    },
    throttledHandleRegister: _.throttle(
      function() {
        this.handleRegister();
      },
      1000,
      { leading: true, trailing: false },
    ), // 1 second interval
    async handleRegister() {
      if (!this.form.checked) {
        Message.warning('请先勾选隐私协议');
        return;
      }
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          let result = await register(this.form);
          if (result.code === 0) {
            // 注册成功
            await login({
              mobile: this.form.mobile,
              password: this.form.password,
              identity: this.form.identity,
            });
            this.$router.push({
              name: 'user',
            });
          } else {
            Message.warning(result.msg);
          }
          console.log(this.form, result);
        }
      });
    },
    handleChangeRole(item) {
      this.form.identity = item.id;
      this.resetForm();
    },
    resetForm() {
      this.form = {
        ...this.form,
        mobile: '',
        password: '',
      };
      this.durations = -1;
    },
    //验证码按钮函数
    async startDuration() {
      if (this.isSend) return;
      let result = await getRegCaptcha({ phoneNumber: this.form.mobile, type: 1, identity: this.form.identity });
      if (result.code !== 0) {
        this.$message.warning(result.msg);
        return;
      }
      this.$message.success(`发送手机验证码成功`);
      this.isSend = true;
      this.duration = this.totalTime + 's后重新发送';
      this.timer = setInterval(() => {
        this.totalTime--;
        this.duration = this.totalTime + 's后重新发送';
        if (this.totalTime < 1) {
          clearInterval(this.timer);
          this.duration = '重新发送验证码';
          this.totalTime = 60;
          this.isSend = false;
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss">
.register_form {
  width: 287px;
  min-height: 313px;
  margin: 54px auto 0;
  padding: 0 18px;
  box-sizing: border-box;

  transform: translateY(-100px);
  .register_title {
    width: 100%;
    text-align: center;
    height: 29px;
    font-size: 25px;
    color: #000000;
    line-height: 29px;
    letter-spacing: 1px;
    font-style: normal;
    margin-bottom: 27px;
  }
  .register_role {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .register_role_title {
      height: 18px;
      font-size: 13px;
      color: #999999;
      line-height: 18px;
      font-style: normal;
    }

    .register_role_wrapper {
      display: flex;
      margin-top: 11px;
      width: 100%;
      justify-content: space-around;
      margin-bottom: 22px;
      .register_role_item {
        width: 72px;
        height: 27px;
        background: #ffffff;
        border-radius: 22px;
        border: 1px solid #acd0ff;
        font-size: 13px;
        color: #3589f6;
        line-height: 27px;
        font-style: normal;
        text-align: center;
        cursor: pointer;
      }
      .is_active {
        background: #3589f6;
        color: #ffffff;
      }
    }
  }
  .el-input {
    // width: 251px;

    .el-input__inner {
      height: 32px;
      background: #ffffff;
      line-height: 32px;
      border-radius: 22px;
      border: 1px solid #cecece;
    }
  }
  .suffix_code {
    color: #0d65da !important;
    margin-right: 16px !important;
    font-size: 13px !important;
    cursor: pointer;
  }
  .office {
    width: 208px;
    margin-left: 68px;
    display: flex;
    margin-bottom: 6px;
    .left {
      width: 13px;
      height: 13px;
      margin-top: -1px;
    }
    .right {
      margin-left: 4px;
    }
  }
  .text {
    text-align: center;
    cursor: pointer;
    span {
      color: #0d65da;
    }
  }
  .btn {
    height: 39px;
    line-height: 39px;
    background: linear-gradient(270deg, #66a9ff 0%, #3589f6 100%);
    border-radius: 4px;
    font-family: MicrosoftYaHeiUI;
    font-size: 16px;
    color: #ffffff;
    text-align: center;
    margin: 22px 0;
    cursor: pointer;
  }
  .mobile {
    .el-input__inner {
      // height: 32px;
      background: #ffffff;
      border-radius: 0 22px 22px 0;
      border: 1px solid #cecece;
    }
    .el-input-group__prepend {
      background: rgba(255, 255, 255, 1);
      border-radius: 22px 0 0 22px;
    }
    .el-select {
      width: 100px;
      font-size: 13px;
      font-style: normal;
      color: #000;
      .el-input__icon {
        line-height: 32px;
      }
    }
  }
  .info {
    width: 100%;
    height: 16px;
    font-family: MicrosoftYaHeiUI;
    font-size: 13px;
    color: #999999;
    line-height: 16px;
    text-align: center;
    font-style: normal;
    cursor: pointer;
    span {
      &:last-child {
        color: #0d65da;
      }
    }
  }
}
</style>
