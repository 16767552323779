/**
 * 长度截取
 * @param {string} value 待截取字符串
 * @param {string|number} length 需要截取的长度
 */
function limitLenFilter(value, length) {
  if (!length) {
    length = 0;
  }
  if (!value) {
    return '';
  }
  return value.slice(Number(length));
}

export default {
  limitLenFilter,
};
