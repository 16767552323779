var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { float: "left" } },
    [
      _vm.item.children && _vm.item.children.length > 0
        ? _c(
            "div",
            [
              _c(
                "el-submenu",
                {
                  attrs: { "popper-append-to-body": "", index: _vm.item.name }
                },
                [
                  _c("template", { slot: "title" }, [
                    _vm._v(_vm._s(_vm.item.meta.title))
                  ]),
                  _vm._l(_vm.item.children, function(child, index) {
                    return _c("menu-item", {
                      key: child.path + index,
                      attrs: {
                        item: child,
                        "base-path": _vm.resolvePath(child.path, child, "sub"),
                        index: child.name
                      }
                    })
                  })
                ],
                2
              )
            ],
            1
          )
        : _c(
            "AppLink",
            {
              staticStyle: { float: "left" },
              attrs: { to: _vm.resolvePath(_vm.item.path, _vm.item, "one") }
            },
            [
              _c("el-menu-item", { attrs: { index: _vm.item.name } }, [
                _vm._v(_vm._s(_vm.item.meta.title))
              ])
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }