var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "avaiation_wrapper" }, [
    _c("div", { staticClass: "download_center" }, [
      _c("div", { staticClass: "download_title" }, [
        _c("div", [_vm._v("下载中心")]),
        _c(
          "div",
          {
            staticClass: "icon_arrow pointer",
            on: {
              click: function($event) {
                return _vm.handlePushDown()
              }
            }
          },
          [
            _c("img", {
              attrs: { src: require("@/assets/images/icon_arrow.png"), alt: "" }
            })
          ]
        )
      ]),
      _c(
        "div",
        { staticClass: "download_list" },
        _vm._l(_vm.list, function(item) {
          return _c("div", { key: item.id, staticClass: "download_item" }, [
            _c("div", { staticClass: "download_item_title" }, [
              _vm._v(_vm._s(item.appName))
            ]),
            _c("div", {
              staticClass: "download_item_icon pointer",
              on: {
                click: function($event) {
                  return _vm.downloadFile(item)
                }
              }
            })
          ])
        }),
        0
      )
    ]),
    _c("div", { staticClass: "avaiation_center" }, [
      _c("div", { staticClass: "avaiation_title" }, [_vm._v("资讯中心")]),
      _c("div", { staticClass: "avaiation_content" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "avaiation_content_list" },
          _vm._l(_vm.aviationList, function(item) {
            return _c(
              "div",
              {
                key: item.id,
                staticClass: "avaition_content_item",
                on: {
                  click: function($event) {
                    return _vm.handlePush(item)
                  }
                }
              },
              [
                _c("div", { staticClass: "avaition_content_title" }, [
                  _vm._v(_vm._s(item.title))
                ]),
                _c("div", { staticClass: "avaition_content_time" }, [
                  _vm._v(_vm._s(item.publishDate))
                ])
              ]
            )
          }),
          0
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "avaiation_content_bg" }, [
      _c("div", { staticClass: "bg" }),
      _c("div", { staticClass: "bg" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }