var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "login" }, [
    _vm._m(0),
    _c("div", { staticClass: "login_wrapper" }, [
      _c(
        "div",
        { staticClass: "form_wrapper" },
        [
          _vm.mode === "login"
            ? _c("LoginForm", {
                on: {
                  changeMode: _vm.changeLoginMode,
                  resetMode: function($event) {
                    _vm.mode = "resetPassWord"
                  }
                }
              })
            : _vm.mode === "resetPassWord"
            ? _c("ResetPassWord", {
                on: {
                  changeMode: function($event) {
                    _vm.mode = "login"
                  }
                }
              })
            : _c("RegisterForm", {
                attrs: { mobile: _vm.mobile },
                on: {
                  changeMode: function($event) {
                    _vm.mode = "login"
                  },
                  changePrivacyPolicy: function($event) {
                    _vm.privacyPolicy = true
                  }
                }
              })
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "dialog-box" },
      [
        _c(
          "el-dialog",
          {
            attrs: {
              title: "隐私协议",
              visible: _vm.privacyPolicy,
              width: "50%",
              modal: "false",
              "custom-class": "dialog-height",
              center: ""
            },
            on: {
              "update:visible": function($event) {
                _vm.privacyPolicy = $event
              }
            }
          },
          [
            _c("PrivacyPolicy"),
            _c(
              "div",
              { staticClass: "privacyPolicyBtn" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "mini" },
                    on: {
                      click: function($event) {
                        _vm.privacyPolicy = false
                      }
                    }
                  },
                  [_vm._v("我已知晓并同意")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "bk_wrapper" }, [
      _c("div", { staticClass: "logo" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }