<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>

<script>
import storage from '@/utils/storage.js';
export default {
  name: 'app',
  mounted() {
    // 18763456721  个人 18763540001 教练 18905342278 团体
    window.addEventListener('beforeunload', () => localStorage.removeItem('dyncRoute'));
    window.addEventListener('unload', () => localStorage.removeItem('dyncRoute'));
    this.$store.commit('permission/SET_RELOAD_MENU', storage.getData('menu'));
    this.$store.commit('permission/SET_USER_INFO', storage.getDataLocal('userInfo'));
    this.$store.commit('permission/SET_TOP_ROUTERS');
  },
};
</script>
