<template>
  <div>
    <!-- <div v-if="hidden" class="hidden" @click="hidden = false">
      <img src="../../assets/images/customerService/hidden.png" alt="" />
    </div> -->
    <div>
      <div v-drag class="content">
        <el-tooltip popper-class="tooltip-class" effect="light" placement="left">
          <div slot="content"><img class="wxCode" src="@/assets/images/bottomLogo1.jpg" alt="" /></div>
          <img class="img1" src="../../assets/images/pop_01.png" alt="" />
        </el-tooltip>
        <el-tooltip popper-class="tooltip-class" effect="light" placement="left">
          <div slot="content">联系电话：18612833500</div>
          <img class="img1" src="../../assets/images/pop_02.png" alt="" />
        </el-tooltip>
        <!-- <img class="img1" src="../../assets/images/pop_04.png" alt="" /> -->

        <img @click="scrollToTop" v-if="showBtn" class="img2" src="../../assets/images/pop_03.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'suspension',
  data() {
    return {
      hidden: false,
      showBtn: false,
      qrCode: '',
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      const scrollDistance = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollDistance > 100) {
        this.showBtn = true;
      } else {
        this.showBtn = false;
      }
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // 平滑滚动
      });
    },
  },
};
</script>
<style lang="scss">
.el-tooltip__popper[x-placement^='left'] .popper__arrow {
  display: none;
}
.tooltip-class {
  border-color: #acd3ff !important;
}
.wxCode {
  width: 200px;
  height: 200px;
}
</style>
<style lang="scss" scoped>
.content {
  width: 76px;
  height: auto;
  position: fixed;
  top: 382px;
  right: 15px;
  cursor: pointer;
  z-index: 99999;
}
.content > :nth-child(1) {
  margin-top: 20px;
}
.img1 {
  width: 76px;
  height: 76px;
  text-align: center;
  cursor: pointer;
}
.img2 {
  width: 76px;
  height: 76px;
  text-align: center;
  cursor: pointer;
}
.img3 {
  width: 76px;
  height: 76px;
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
  margin-bottom: 20px;
}
.hidden {
  position: fixed;
  top: 40%;
  right: 0px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
</style>
