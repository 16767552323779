var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "form",
      staticClass: "login_form",
      attrs: { model: _vm.form, rules: _vm.rules }
    },
    [
      _c("div", { staticClass: "login_title" }, [_vm._v("欢迎登录")]),
      _c(
        "div",
        { staticClass: "login_tab" },
        _vm._l(_vm.tabs, function(item) {
          return _c(
            "div",
            {
              key: item.id,
              class: _vm.tabsActive === item.id ? "tab is_active " : "tab",
              on: {
                click: function($event) {
                  _vm.tabsActive = item.id
                }
              }
            },
            [_vm._v(" " + _vm._s(item.title) + " ")]
          )
        }),
        0
      ),
      _c("div", { staticClass: "register_role" }, [
        _c("div", { staticClass: "register_role_title" }, [
          _vm._v("请选择角色")
        ]),
        _c(
          "div",
          { staticClass: "register_role_wrapper" },
          _vm._l(_vm.roles, function(item) {
            return _c(
              "div",
              {
                key: item.id,
                class:
                  _vm.form.identity === item.id
                    ? "register_role_item is_active"
                    : "register_role_item",
                on: {
                  click: function($event) {
                    return _vm.handleChangeRole(item)
                  }
                }
              },
              [_vm._v(" " + _vm._s(item.label) + " ")]
            )
          }),
          0
        )
      ]),
      _vm.tabsActive === 1
        ? _c(
            "div",
            [
              _c(
                "el-form-item",
                { attrs: { prop: "user" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入手机号码/邮箱" },
                    model: {
                      value: _vm.form.user,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "user", $$v)
                      },
                      expression: "form.user"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "password" } },
                [
                  _c("el-input", {
                    attrs: {
                      "show-password": "",
                      placeholder: "请输入密码",
                      type: "password"
                    },
                    model: {
                      value: _vm.form.password,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "password", $$v)
                      },
                      expression: "form.password"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "el-form-item",
                { staticClass: "mobile", attrs: { prop: "user" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { placeholder: "请输入手机号码" },
                      model: {
                        value: _vm.form.user,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "user", $$v)
                        },
                        expression: "form.user"
                      }
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "prepend" }, slot: "prepend" },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.value,
                                callback: function($$v) {
                                  _vm.value = $$v
                                },
                                expression: "value"
                              }
                            },
                            _vm._l(_vm.options, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "password" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { placeholder: "请输入验证码" },
                      model: {
                        value: _vm.form.password,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "password", $$v)
                        },
                        expression: "form.password"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "suffix_code",
                          attrs: { slot: "suffix" },
                          on: { click: _vm.startDuration },
                          slot: "suffix"
                        },
                        [_vm._v(_vm._s(_vm.duration))]
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
      _c("div", { staticClass: "opraty" }, [
        _c(
          "div",
          { staticClass: "opraty_left", on: { click: _vm.handleChangeMode } },
          [_c("span", [_vm._v("没有帐号？")]), _c("span", [_vm._v("前去注册")])]
        ),
        _c(
          "div",
          { staticClass: "opraty_right", on: { click: _vm.handleReset } },
          [_c("span", [_vm._v("忘记密码")])]
        )
      ]),
      _c("div", { staticClass: "btn", on: { click: _vm.handleLogin } }, [
        _vm._v("登录")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }