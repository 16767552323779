import remote from '@/services/remote';

export default {
  downloadList(payload) {
    return remote.get('/download/list', {
      payload,
    });
  },
  downloadFile(payload) {
    return remote.getBlob('/file/get-attachment', {
      payload,
    });
  },
  downloadTemplateFile(payload) {
    return remote.getBlob('/file/get-template', {
      payload,
    });
  },
};
