const getters = {
  topRouters: (state) => state.permission.topRouters,
  topChildSlidRouters: (state) => state.permission.topChildSlidRouters,
  isSilder: (state) => state.permission.isSilder,
  topName: (state) => state.permission.topName,
  topPath: (state) => state.permission.topPath,
  activeIndex: (state) => state.permission.activeIndex,
  activeIndexSlider: (state) => state.permission.activeIndexSlider,
  userInfo: (state) => state.permission.userInfo,
};
export default getters;
