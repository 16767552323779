import layoutHeaderAside from '@/layout';
import login from '@/views/system/login';
import index from '@/views/system/index/index';
// import { ROUTERTOP } from '../config/router-level';
import personalData from './modules/personal-data';
import { ROUTERTOP, ROUTESILDE } from '../config/router-level';
// import home from './modules/home';
const _import = require('@/utils/util.import');

export const person = {
  path: '/user',
  name: 'user',
  meta: {
    title: '个人中心',
    level: ROUTERTOP,
    hideTop: true, // 在顶部导航栏隐藏
    noneed: true,
    redirect: {
      name: 'personbase',
    },
  },
  // redirect: {
  //   name: 'personbase',
  // },
  component: {
    render(c) {
      return c('router-view', { key: 3 });
    },
  },
  children: [
    {
      path: 'person',
      name: 'person',
      component: {
        render(c) {
          return c('router-view', { key: 3 });
        },
      },
      meta: {
        title: '个人资料',
        level: ROUTESILDE,
        identity: '1',
        noneed: true,
      },
      children: [
        {
          path: 'person',
          name: 'personbase',
          component: _import('person/mine'),
          meta: {
            title: '基本信息',
            desc: 'PERSONAL INFOMATION',
            level: ROUTESILDE,
            identity: '1',
            noneed: true,
          },
        },
        {
          path: 'stu',
          name: 'stu',
          component: _import('person/stu'),
          meta: {
            title: '教育经历',
            desc: 'EDUCATIONAL EXPERIENCE',
            level: ROUTESILDE,
            identity: '1',
          },
        },
      ],
    },

    {
      path: 'game',
      name: 'game',
      component: {
        render(c) {
          return c('router-view', { key: 3 });
        },
      },
      meta: {
        title: '赛事办理',
        level: ROUTESILDE,
        identity: '1',
      },
      children: [
        {
          path: 'bm',
          name: 'bm',
          component: _import('person/eventRegistration'),
          meta: {
            title: '赛事报名',
            level: ROUTESILDE,
            desc: 'Event registration',
            identity: '1',
          },
        },
        {
          path: 'mybm',
          name: 'mybm',
          component: _import('person/mybm'),
          meta: {
            title: '我的报名',
            level: ROUTESILDE,
            desc: 'MY SIGNUP',
            identity: '1',
          },
        },
        {
          path: 'myss',
          name: 'myss',
          component: _import('person/myCompetition'),
          meta: {
            title: '我的赛事',
            level: ROUTESILDE,
            desc: 'MY Competition',
            identity: '1',
          },
        },
        {
          path: 'myzs',
          name: 'myzs',
          component: _import('person/myCert'),
          meta: {
            title: '我的证书',
            level: ROUTESILDE,
            desc: 'MY Cert',
            identity: '1',
          },
        },
      ],
    },

    {
      path: 'account',
      name: 'account',
      component: {
        render(c) {
          return c('router-view', { key: 3 });
        },
      },
      meta: {
        title: '账号设置',
        level: ROUTESILDE,
      },
      children: [
        {
          path: 'change',
          name: 'change',
          component: _import('person/changPassword'),
          meta: {
            title: '修改密码',
            desc: 'MODIFY PASSWORD',
            level: ROUTESILDE,
          },
        },
      ],
    },
  ],
};

export const team = {
  path: '/user',
  name: 'user',
  meta: {
    title: '团队资料',
    level: ROUTERTOP,
    hideTop: true, // 在顶部导航栏隐藏
    redirect: {
      name: 'teambase',
    },
    noneed: true,
  },

  // redirect: {
  //   name: 'teambase',
  // },
  component: {
    render(c) {
      return c('router-view', { key: 3 });
    },
  },
  children: [
    {
      path: 'team',
      name: 'team',
      component: {
        render(c) {
          return c('router-view', { key: 3 });
        },
      },
      meta: {
        title: '团队资料',
        level: ROUTESILDE,
        identity: '1',
        noneed: true,
      },
      children: [
        {
          path: 'person',
          name: 'teambase',
          component: _import('team/mine'),
          meta: {
            title: '基本信息',
            desc: 'PERSONAL INFOMATION',
            level: ROUTESILDE,
            noneed: true,
          },
        },
        {
          path: 'stuBind',
          name: 'stuBind',
          component: _import('team/studentBinding'),
          meta: {
            title: '个人绑定',
            desc: 'Student Binding',
            level: ROUTESILDE,
          },
        },
        {
          path: 'teacherBind',
          name: 'teacherBind',
          component: _import('team/teacherBinding'),
          meta: {
            title: '辅导教师绑定',
            desc: 'Teacher Binding',
            level: ROUTESILDE,
          },
        },
      ],
    },

    {
      path: 'game',
      name: 'game',
      component: {
        render(c) {
          return c('router-view', { key: 3 });
        },
      },
      meta: {
        title: '赛事办理',
        level: ROUTESILDE,
        identity: '1',
      },
      children: [
        {
          path: 'bm',
          name: 'bm',
          component: _import('team/eventRegistration'),
          meta: {
            title: '赛事报名',
            level: ROUTESILDE,
            desc: 'Event registration',
            identity: '1',
          },
        },
        {
          path: 'mybm',
          name: 'mybm',
          component: _import('team/mybm'),
          meta: {
            title: '我的报名',
            level: ROUTESILDE,
            desc: 'MY SIGNUP',
            identity: '1',
          },
        },
        {
          path: 'myss',
          name: 'myss',
          component: _import('team/myCompetition'),
          meta: {
            title: '我的赛事',
            level: ROUTESILDE,
            desc: 'MY Competition',
            identity: '1',
          },
        },
        {
          path: 'myzs',
          name: 'myzs',
          component: _import('team/myCert'),
          meta: {
            title: '我的证书',
            level: ROUTESILDE,
            desc: 'MY Cert',
            identity: '1',
          },
        },
      ],
    },

    {
      path: 'account',
      name: 'account',
      component: {
        render(c) {
          return c('router-view', { key: 3 });
        },
      },
      meta: {
        title: '账号设置',
        level: ROUTESILDE,
      },
      children: [
        {
          path: 'change',
          name: 'change',
          component: _import('person/changPassword'),
          meta: {
            title: '修改密码',
            desc: 'MODIFY PASSWORD',
            level: ROUTESILDE,
          },
        },
      ],
    },
  ],
};

export const coach = {
  path: '/user',
  name: 'user',
  meta: {
    title: '教练资料',
    level: ROUTERTOP,
    hideTop: true, // 在顶部导航栏隐藏
    redirect: {
      name: 'personmine',
    },
    noneed: true,
  },
  component: {
    render(c) {
      return c('router-view', { key: 3 });
    },
  },
  children: [
    {
      path: 'coach',
      name: 'coach',
      component: {
        render(c) {
          return c('router-view', { key: 3 });
        },
      },
      meta: {
        title: '教练资料',
        level: ROUTESILDE,
        identity: '2',
        noneed: true,
      },
      children: [
        {
          path: 'person',
          name: 'personmine',
          component: _import('coach/mine'),
          meta: {
            title: '基本信息',
            desc: 'PERSONAL INFOMATION',
            level: ROUTESILDE,
            noneed: true,
          },
        },
      ],
    },
    {
      path: 'teacher',
      name: 'teacher',
      component: {
        render(c) {
          return c('router-view', { key: 3 });
        },
      },
      meta: {
        title: '师资管理',
        level: ROUTESILDE,
      },
      children: [
        {
          path: 'qualification',
          name: 'qualification',
          component: _import('coach/qualification'),
          meta: {
            title: '资格认证',
            desc: 'QUALIFICATION',
            level: ROUTESILDE,
          },
        },
        {
          path: 'myzs',
          name: 'myzs',
          component: _import('coach/myCert'),
          meta: {
            title: '我的证书',
            level: ROUTESILDE,
            desc: 'MY Cert',
            identity: '2',
          },
        },
      ],
    },
    {
      path: 'account',
      name: 'account',
      component: {
        render(c) {
          return c('router-view', { key: 3 });
        },
      },
      meta: {
        title: '账号设置',
        level: ROUTESILDE,
      },
      children: [
        {
          path: 'change',
          name: 'change',
          component: _import('person/changPassword'),
          meta: {
            title: '修改密码',
            desc: 'MODIFY PASSWORD',
            level: ROUTESILDE,
          },
        },
      ],
    },
  ],
};
// 导出需要显示菜单的
export const frameInRoutes = [personalData];
/**
 * 在主框架内显示  二级路由嵌套 组件使用 return c('router-view', { key: 3 });
 */
export const frameIn = [
  {
    path: '/',
    name: 'home',
    redirect: {
      name: 'index',
    },
    component: layoutHeaderAside,
    children: [
      {
        path: '/index',
        name: 'index',
        meta: {
          title: '首页',
          business: {
            callDeposit: 'start',
          },
          level: ROUTERTOP,
        },
        component: index,
      },
      {
        path: '/eventinformation',
        name: 'eventinformation',
        component: _import('system/eventinformation'),
        meta: {
          title: '赛事信息',
          level: ROUTERTOP,
        },
      },
      {
        path: '/eventinformationDetail',
        name: 'eventinformationDetail',
        component: _import('system/eventinformation/eventinformation-detail'),
        meta: {
          hideTop: true, // 在顶部导航栏隐藏
          title: '赛事信息详情',
          level: ROUTERTOP,
        },
      },
      {
        path: '/aviationcompetition',
        name: 'aviationcompetition',
        component: _import('system/aviationcompetition'),
        meta: {
          title: '航赛资讯',
          level: ROUTERTOP,
        },
      },
      {
        path: '/aboutUs',
        name: 'aboutUs',
        component: _import('system/aboutUs'),
        meta: {
          hideTop: true, // 在顶部导航栏隐藏
          title: '关于我们',
          level: ROUTERTOP,
        },
      },
      {
        path: '/contactUs',
        name: 'contactUs',
        component: _import('system/contactUs'),
        meta: {
          hideTop: true, // 在顶部导航栏隐藏
          title: '联系我们',
          level: ROUTERTOP,
        },
      },
      {
        path: '/aviationcompetitionDetail',
        name: 'aviationcompetitionDetail',
        component: _import('system/aviationcompetition/aviationcompetition-detail'),
        meta: {
          hideTop: true, // 在顶部导航栏隐藏
          title: '航赛资讯详情',
          level: ROUTERTOP,
        },
      },
      {
        path: '/flightRanking',
        name: 'flightRanking',
        component: _import('system/flightRanking'),
        meta: {
          title: '飞行榜单',
          level: ROUTERTOP,
        },
      },
      {
        path: '/flightRankingDetail',
        name: 'flightRankingDetail',
        component: _import('system/flightRanking/flightRanking-detail'),
        meta: {
          hideTop: true, // 在顶部导航栏隐藏
          title: '飞行榜单详情',
          level: ROUTERTOP,
        },
      },
      // {
      //   path: '/trainingcourses',
      //   name: 'trainingcourses',
      //   component: _import('system/trainingcourses'),
      //   meta: {
      //     title: '实训课程',
      //     level: ROUTERTOP,
      //   },
      // },
      {
        path: '/certifiedmaster',
        name: 'certifiedmaster',
        component: _import('system/certifiedmaster'),
        meta: {
          title: '认证名师',
          level: ROUTERTOP,
        },
      },
      {
        path: '/certifiedmasterDetail',
        name: 'certifiedmasterDetail',
        component: _import('system/certifiedmaster/certifiedmaster-detail'),
        meta: {
          hideTop: true, // 在顶部导航栏隐藏
          title: '认证名师',
          level: ROUTERTOP,
        },
      },

      {
        path: '/schoolstyle',
        name: 'schoolstyle',
        component: _import('system/schoolstyle'),
        meta: {
          title: '航校风采',
          level: ROUTERTOP,
        },
      },
      {
        path: '/schoolstyleDetail',
        name: 'schoolstyleDetail',
        component: _import('system/schoolstyle/schoolstyle-detail'),
        meta: {
          hideTop: true, // 在顶部导航栏隐藏
          title: '航校风采详情',
          level: ROUTERTOP,
        },
      },
      {
        path: '/downPage',
        name: 'downPage',
        component: _import('system/downPage'),
        meta: {
          title: '下载中心',
          level: ROUTERTOP,
        },
      },
    ],
  },
  ...frameInRoutes,
];

/**
 * 在主框架之外显示
 */
const frameOut = [
  // 登录
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: {
      skipAuth: true,
    },
  },
  //注册
  {
    path: '/register',
    name: 'register',
    component: _import('system/register/register-pre'),
    meta: {
      title: '注册录入页',
      skipAuth: true,
    },
  },
];

/**
 * 错误页面
 */

const errorPage = [
  {
    path: '/500',
    name: '500',
    component: _import('system/error/500'),
  },
];

// 重新组织后导出
export default [...frameIn, ...frameOut, ...errorPage];
