const networkCodes = {
  505: 'HTTP版本不受支持',
  504: '网关超时',
  502: '网关错误',
  501: '服务未实现',
  500: '服务器内部错误',
  404: '资源不存在',
  403: '请求被禁止',
  401: '请求未认证',
  400: '糟糕的请求',
};

const businessCodes = {
  400000: '糟糕的请求',
};
export default { network: networkCodes, business: businessCodes };
