<template>
  <div :class="$router.app._route.name === 'index' ? 'layout-wrapper index-wrapper' : 'layout-wrapper'">
    <div :class="navBarFixed == true ? 'navBarWrap' : ''">
      <Header />
    </div>
    <Session />
    <Footer />
  </div>
</template>
<script>
import Header from './components/header.vue';
import Session from './components/session.vue';
import Footer from './components/footer.vue';
export default {
  name: 'layout-header-aside',
  components: {
    Header,
    Session,
    Footer,
  },
  data() {
    return {
      navBarFixed: false,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    // 事件监听滚动条
    window.addEventListener('scroll', this.watchScroll, true);
  },
  methods: {
    watchScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

      //  当滚动超过 50 时，实现吸顶效果

      if (scrollTop > 49) {
        this.navBarFixed = true;
      } else {
        this.navBarFixed = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/style/layout.scss';

.layout-wrapper {
  height: 100%;
  // display: flex;
  // flex-direction: column;
  .layout-session {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #f2f2f2;
    // .container {
    //   overflow: hidden;
    // }
  }
  .layout-footer {
    height: 360px;
    background: #21262b;
  }
}
.index-wrapper {
  display: block;
}
.navBarWrap {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
}
</style>
