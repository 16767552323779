var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "teacher_wrapper pointer" }, [
    _c("div", { staticClass: "swiper swiperTeacher" }, [
      _c(
        "div",
        { staticClass: "swiper-wrapper" },
        _vm._l(_vm.list, function(itemChild) {
          return _c(
            "div",
            {
              key: itemChild,
              staticClass: "swiper-slide carousel_item_child",
              on: {
                click: function($event) {
                  return _vm.handlePush(itemChild)
                }
              }
            },
            [
              _c("div", { staticClass: "carousel_item_child_img" }, [
                _c("img", { attrs: { src: itemChild.avatar, alt: "" } }),
                _c("div", { staticClass: "teacher_name" }, [
                  _vm._v(_vm._s(itemChild.nickname))
                ])
              ]),
              _c(
                "div",
                { staticClass: "teacher_leval_box" },
                _vm._l(itemChild.certificationList, function(item) {
                  return _c(
                    "el-tag",
                    {
                      key: item.certification,
                      staticClass: "teacher_leval",
                      attrs: { type: "warning", effect: "dark" }
                    },
                    [_vm._v(" " + _vm._s(item.certification) + " ")]
                  )
                }),
                1
              ),
              _c("div", { staticClass: "carousel_item_child_content" }, [
                _c("div", { staticClass: "carousel_item_child_label" }, [
                  _vm._v("基本信息：")
                ]),
                _c("div", { staticClass: "carousel_item_child_value" }, [
                  _vm._v(_vm._s(itemChild.introductory || "无"))
                ])
              ])
            ]
          )
        }),
        0
      ),
      _c("div", { staticClass: "swiper-pagination" })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }