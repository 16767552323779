var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "session",
    { class: _vm.isSilder ? "layout-session top" : "layout-session" },
    [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "keep-alive",
            [
              !_vm.isSilder
                ? _c("router-view", { key: _vm.$route.fullPath })
                : _c(
                    "div",
                    { staticClass: "has-slider" },
                    [
                      _c("div", { staticClass: "body" }),
                      _c("SliderNav"),
                      _c("router-view", {
                        key: _vm.$route.fullPath,
                        staticStyle: { float: "left" }
                      })
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      ),
      _c("suspension")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }