var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    { staticClass: "layout_nav" },
    [
      _c(
        "el-menu",
        {
          staticClass: "el-menu-nav",
          attrs: { "default-active": _vm.activeIndex, mode: "horizontal" },
          on: { select: _vm.handleSelect }
        },
        _vm._l(_vm.topRouters, function(item, index) {
          return _c("MenuItem", {
            key: item.path + index,
            attrs: { type: "nav", item: item, "base-path": item.path }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }