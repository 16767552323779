// import { sessionCache } from '@csii/vx-util';
const defaultKey = '';

export default {
  setData(data, key) {
    key = key || defaultKey;

    sessionStorage.setItem(key, JSON.stringify(data));
  },
  getData(key) {
    key = key || defaultKey;
    return JSON.parse(sessionStorage.getItem(key));
  },
  getDataLocal(key) {
    key = key || defaultKey;
    return JSON.parse(localStorage.getItem(key));
  },
  clearData(key) {
    key = key || defaultKey;
    console.log(key);
    sessionStorage.removeItem(key);
  },
};
