import Vue from 'vue';

// v-drag: 拖拽
Vue.directive('drag', {
  bind: function(el) {
    el.style.cursor = 'move';
    el.onmousedown = (e) => {
      // 鼠标按下，计算当前元素距离可视区的距离
      const disX = e.clientX - el.offsetLeft;
      const disY = e.clientY - el.offsetTop;
      // 获取到的值带px 正则匹配替换
      document.onmousemove = function(e) {
        // 通过事件委托，计算移动的距离
        const l = e.clientX - disX;
        const t = e.clientY - disY;
        // 移动当前元素
        el.style.left = `${l}px`;
        el.style.top = `${t}px`;
        // 将此时的位置传出去
        // binding.value({x:e.pageX,y:e.pageY})
      };
      document.onmouseup = function() {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    };
  },
});
