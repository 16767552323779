import { transformRequest, transformResponse, catchError } from './common';
// import route from '@/router';
import axios from 'axios';
// import { sessionCache } from '@csii/vx-util';
const instance = axios.create({
  baseURL: process.env.VUE_APP_API,
  timeout: 120 * 1000,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: false, // 默认值
});

instance.interceptors.request.use(transformRequest, Promise.reject);

async function axiosCall(options) {
  try {
    return await instance(options)
      .catch(catchError)
      .then(transformResponse);
  } catch (err) {
    console.log(err);
    // 需要重新刷新token
    // await tokenSvc.refreshToken(options.url);
    // 强制登出
    // 强制登出到登录页
    // route.replace({ path: '/login' });
    throw err;
  }
}

export default {
  get: async function(url, options = {}) {
    options.method = 'GET';
    options.url = url;
    options.params = options.payload;
    return axiosCall(options);
  },
  //post接口使用body参数
  post: async function(url, options = {}) {
    options.method = 'POST';
    options.url = url;
    options.data = options.payload || {};
    return axiosCall(options);
  },
  //post接口使用query参数
  postParams: async function(url, options = {}) {
    options.method = 'POST';
    options.url = url;
    options.params = options.payload || {};
    return axiosCall(options);
  },
  put: async function(url, options = {}) {
    options.method = 'PUT';
    options.url = url;
    options.data = options.payload || {};
    return axiosCall(options);
  },
  delete: async function(url, options = {}) {
    options.method = 'DELETE';
    options.url = url;
    options.data = options.payload || {};
    return axiosCall(options);
  },
  // FormData格式
  postForm: async function(url, options = {}) {
    options.method = 'POST';
    const formdata = new FormData();
    const data = options.payload;
    for (const key in data) {
      console.log(key);
      if (Object.hasOwnProperty.call(data, key)) {
        formdata.append(key, data[key]);
      }
    }
    options.headers = Object.assign({}, options.headers || {}, {
      'Content-Type': 'multipart/form-data',
    });
    options.url = url;
    options.data = formdata;
    return axiosCall(options);
  },
  postBlob: async function(url, options = {}) {
    options.method = 'POST';
    options.url = url;
    options.data = options.payload;
    options.responseType = 'blob';
    return axiosCall(options);
  },
  getBlob: async function(url, options = {}) {
    options.method = 'GET';
    options.url = url;
    options.params = options.payload;
    options.responseType = 'blob';
    return axiosCall(options);
  },
};
