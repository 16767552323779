import remote from '@/services/remote';

import store from '@/store/index';
// 验证码

export const getRegCaptcha = async (payload) => {
  try {
    let result = await remote.get('/wx/auth/captcha', {
      payload,
    });
    return result;
  } catch (error) {
    console.warn(error);
  }
};
export const login = async (payload) => {
  try {
    let result = await remote.post('/wx/auth/login', {
      payload,
    });
    if (result.code === 0) {
      localStorage.setItem('web-token', result.data.token);
      localStorage.setItem('identity', result.data?.userInfo?.identity);
      localStorage.setItem('userInfo', JSON.stringify(result.data?.userInfo));
      store.commit('permission/SET_USER_INFO', result.data?.userInfo);
    }
    return result;
  } catch (error) {
    console.warn(error);
  }
};
//验证码登录
export const loginCode = async (payload) => {
  try {
    let result = await remote.post('/wx/auth/login_code', {
      payload,
    });
    if (result.code === 0) {
      localStorage.setItem('token', result.data.token);
      localStorage.setItem('identity', result.data?.userInfo?.identity);
      localStorage.setItem('userInfo', JSON.stringify(result.data?.userInfo));
      store.commit('permission/SET_USER_INFO', result.data?.userInfo);
    }
    return result;
  } catch (error) {
    console.warn(error);
  }
};

export const register = async (payload) => {
  try {
    let result = await remote.post('/wx/auth/register-user', {
      payload,
    });
    return result;
  } catch (error) {
    console.warn(error);
  }
};
