<template>
  <el-form class="reset_form" :model="form" :rules="rules" ref="form">
    <div class="reset-step">
      <el-steps :active="active">
        <el-step></el-step>
        <el-step></el-step>
        <el-step></el-step>
      </el-steps>
    </div>
    <div class="reset_title">找回密码</div>
    <div v-if="active === 1">
      <div class="reset_text">输入 “{{ typeText }}” 填写验证信息，通过验证后即可重设密码。</div>
      <div v-if="resetType === 1">
        <div class="register_role">
          <div class="register_role_title">请选择角色</div>
          <div class="register_role_wrapper">
            <div
              :class="form.identity === item.id ? 'register_role_item is_active' : 'register_role_item'"
              @click="handleChangeRole(item)"
              v-for="item in roles"
              :key="item.id"
            >
              {{ item.label }}
            </div>
          </div>
        </div>
      </div>
      <el-form-item class="phoneNumber" prop="phoneNumber" v-if="resetType === 1">
        <el-input v-model="form.phoneNumber" placeholder="请输入手机号码">
          <div slot="prepend">
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
        </el-input>
      </el-form-item>
      <el-form-item prop="email" v-if="resetType === 2">
        <el-input v-model="form.email" placeholder="请输入注册的邮箱"> </el-input>
      </el-form-item>
      <el-form-item prop="userName" v-if="resetType === 3">
        <el-input v-model="form.userName" placeholder="请输入注册登录名"> </el-input>
      </el-form-item>
      <el-form-item prop="nickName" v-if="resetType === 3">
        <el-input v-model="form.nickName" placeholder="请输入注册用户姓名或单位名称"> </el-input>
      </el-form-item>
      <el-form-item prop="teamName" v-if="resetType === 3">
        <el-input v-model="form.teamName" placeholder="请输入参赛队名称"> </el-input>
      </el-form-item>
      <el-form-item prop="code" v-if="resetType !== 3">
        <el-input v-model="form.code" placeholder="请输入验证码">
          <div slot="suffix" @click="startDuration" class="suffix_code">{{ duration }}</div>
        </el-input>
      </el-form-item>
      <div class="reset-office">
        <div class="left">
          <div v-if="resetType !== 2" @click="byEmail">通过邮箱找回</div>
          <div v-else @click="byNumber">通过手机找回</div>
        </div>
        <div class="right">
          <div v-if="resetType !== 3" @click="byUser">通过登录名验证信息找回</div>
          <div v-else @click="byNumber">通过手机找回</div>
        </div>
      </div>
      <div class="btn" @click="next(1)">下一步</div>
      <div class="info">
        <span>手机号已不再使用？</span>
        <span>点此解决</span>
      </div>
    </div>
    <div v-else-if="active === 2">
      <div class="reset_text">请重新设置新的密码</div>
      <el-form-item prop="password">
        <el-input show-password v-model="form.password" placeholder="请输入密码" type="password"> </el-input>
      </el-form-item>
      <div class="btn" @click="next(2)">确定修改密码</div>
    </div>
    <div v-else>
      <div class="reset_success">恭喜您，密码重置成功！</div>
      <div class="btn" @click="handleChangeMode">返回登录</div>
    </div>
  </el-form>
</template>

<script>
import { getRegCaptcha } from '@/api/login.js';
import { checkRegCaptcha, checkEmail, checkUserInfo, forgetByCode, forgetByEmail, forgetByUserInfo } from '@/api/resetPassword.js';
import { Message } from 'element-ui';
export default {
  name: 'resetForm',
  data() {
    return {
      typeText: '手机号',
      active: 1,
      resetType: 1, //找回方式 1手机号 2 邮箱 3 用户名
      roles: [
        {
          id: 1,
          label: '个人',
          value: 1,
        },
        {
          id: 2,
          label: '教练',
          value: 2,
        },
        {
          id: 3,
          label: '团体',
          value: 3,
        },
      ],
      form: {
        phoneNumber: '',
        code: '',
        nickName: '',
        email: '',
        userName: '',
        teamName: '',
        identity: 1,
      },
      options: [
        {
          value: '中国+86',
          label: '中国+86',
        },
      ],
      value: '中国+86',
      durations: -1,
      rules: {
        phoneNumber: [{ required: true, message: '请输入手机号码', trigger: 'change' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'change' }],
        password: [{ required: true, message: '请输入密码', trigger: 'change' }],
        email: [{ required: true, message: '请输入邮箱', trigger: 'change' }],
        userName: [{ required: true, message: '请输入登录名', trigger: 'change' }],
        nickName: [{ required: true, message: '请输入注册用户姓名或单位名称', trigger: 'change' }],
        teamName: [{ required: true, message: '请输入参赛队名称', trigger: 'change' }],
      },
      duration: '获取验证码',
      isSend: '',
      totalTime: 60,
    };
  },
  created() {},
  methods: {
    //验证码按钮函数
    async startDuration() {
      if (this.isSend) return;
      if (this.resetType === 2) {
        let result = await checkEmail({ email: this.form.email });
        if (result.code !== 0) {
          this.$message.warning(result.msg);
          return;
        }
      } else {
        let result = await getRegCaptcha({ phoneNumber: this.form.phoneNumber, type: 3, identity: this.form.identity });
        if (result.code !== 0) {
          this.$message.warning(result.msg);
          return;
        }
      }
      this.$message.success(`验证码发送成功`);
      this.isSend = true;
      this.duration = this.totalTime + 's后重新发送';
      this.timer = setInterval(() => {
        this.totalTime--;
        this.duration = this.totalTime + 's后重新发送';
        if (this.totalTime < 1) {
          clearInterval(this.timer);
          this.duration = '重新发送验证码';
          this.totalTime = 60;
          this.isSend = false;
        }
      }, 1000);
    },
    //通过邮箱
    byEmail() {
      this.resetType = 2;
      this.typeText = '邮箱';
    },
    byNumber() {
      this.resetType = 1;
      this.typeText = '手机号';
    },
    //通过用户名
    byUser() {
      this.resetType = 3;
      this.typeText = '用户名';
    },
    handleChangeMode() {
      this.$emit('changeMode');
    },
    handleChangeRole(item) {
      this.form.identity = item.id;
    },
    next(type) {
      if (type === 1) {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            if (this.resetType === 1 || this.resetType === 2) {
              let result = await checkRegCaptcha(this.form);
              if (result.code === 0) {
                this.active++;
              } else {
                Message.warning(result.msg);
              }
            } else {
              this.form.identity = '';
              const info = {
                nickName: this.form.nickName,
                userName: this.form.userName,
                teamName: this.form.teamName,
              };
              let result = await checkUserInfo(info);
              if (result.code === 0) {
                this.active++;
              } else {
                Message.warning(result.msg);
              }
            }
          }
        });
      } else {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            if (this.resetType === 1) {
              if (!this.form.identity) {
                Message.warning('请先选择角色');
                return;
              }
              let result = await forgetByCode(this.form);
              if (result.code === 0) {
                this.active++;
              } else {
                Message.warning(result.msg);
              }
            } else if (this.resetType === 2) {
              let result = await forgetByEmail(this.form);
              if (result.code === 0) {
                this.active++;
              } else {
                Message.warning(result.msg);
              }
            } else {
              const info = {
                nickName: this.form.nickName,
                userName: this.form.userName,
                teamName: this.form.teamName,
                password: this.form.password,
              };
              let result = await forgetByUserInfo(info);
              if (result.code === 0) {
                this.active++;
              } else {
                Message.warning(result.msg);
              }
            }
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
.reset_form {
  width: 287px;
  min-height: 313px;
  margin: 54px auto 0;
  padding: 0 18px;
  box-sizing: border-box;

  transform: translateY(-100px);
  .reset-step {
    margin-bottom: 24px;
  }
  .reset_title {
    width: 100%;
    text-align: center;
    height: 29px;
    font-size: 25px;
    color: #000000;
    line-height: 29px;
    letter-spacing: 1px;
    font-style: normal;
    margin-bottom: 30px;
  }
  .register_role {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .register_role_title {
      height: 18px;
      font-size: 13px;
      color: #999999;
      line-height: 18px;
      font-style: normal;
    }

    .register_role_wrapper {
      display: flex;
      margin-top: 11px;
      width: 100%;
      justify-content: space-around;
      margin-bottom: 22px;
      .register_role_item {
        width: 72px;
        height: 27px;
        background: #ffffff;
        border-radius: 22px;
        border: 1px solid #acd0ff;
        font-size: 13px;
        color: #3589f6;
        line-height: 27px;
        font-style: normal;
        text-align: center;
        cursor: pointer;
      }
      .is_active {
        background: #3589f6;
        color: #ffffff;
      }
    }
  }
  .reset_text {
    height: 40px;
    font-size: 14px;
    color: #777777;
    line-height: 20px;
    font-style: normal;
    margin-bottom: 24px;
  }
  .reset_success {
    height: 20px;
    font-size: 14px;
    color: #0a62d4;
    line-height: 20px;
    font-style: normal;
  }
  .el-input {
    // width: 251px;

    .el-input__inner {
      height: 32px;
      background: #ffffff;
      line-height: 32px;
      border-radius: 22px;
      border: 1px solid #cecece;
    }
  }
  .suffix_code {
    color: #0d65da !important;
    margin-right: 16px !important;
    font-size: 13px !important;
    cursor: pointer;
  }
  .reset-office {
    display: flex;
    margin-bottom: 6px;
    margin-top: 72px;
    .left {
      float: left;
      height: 18px;
      font-size: 12px;
      color: #0d65da;
      line-height: 18px;
      text-align: left;
      font-style: normal;
      cursor: pointer;
    }
    .right {
      height: 18px;
      font-size: 12px;
      color: #0d65da;
      line-height: 18px;
      text-align: left;
      font-style: normal;
      margin-left: 42px;
      float: right;
      cursor: pointer;
    }
  }
  .btn {
    height: 39px;
    line-height: 39px;
    background: linear-gradient(270deg, #66a9ff 0%, #3589f6 100%);
    border-radius: 4px;
    font-family: MicrosoftYaHeiUI;
    font-size: 16px;
    color: #ffffff;
    text-align: center;
    margin: 22px 0;
    cursor: pointer;
  }
  .phoneNumber {
    .el-input__inner {
      // height: 32px;
      background: #ffffff;
      border-radius: 0 22px 22px 0;
      border: 1px solid #cecece;
    }
    .el-input-group__prepend {
      background: rgba(255, 255, 255, 1);
      border-radius: 22px 0 0 22px;
    }
    .el-select {
      width: 100px;
      font-size: 13px;
      font-style: normal;
      color: #000;
      .el-input__icon {
        line-height: 32px;
      }
    }
  }
  .info {
    width: 100%;
    height: 16px;
    font-family: MicrosoftYaHeiUI;
    font-size: 13px;
    color: #999999;
    line-height: 16px;
    text-align: center;
    font-style: normal;
    cursor: pointer;
    span {
      &:last-child {
        color: #0d65da;
      }
    }
  }
}
</style>
