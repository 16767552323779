import { frameIn } from '@/router/routes';
import { ROUTERTOP, ROUTESILDE } from '../../config/router-level';
import storage from '@/utils/storage.js';
const permission = {
  namespaced: true,
  state: {
    routes: [],
    addRoutes: [],
    sidebarRouters: frameIn,
    topRouters: [],
    topChildSlidRouters: {}, // key 是top的对应key value为对应的chidren数组
    isSilder: false, // 是否有边栏
    topName: '', // 当前顶部
    topPath: '', // 当前顶部url
    activeIndex: 'index', // 顶部激活index
    activeIndexSlider: '', // 侧边激活index
    userInfo: {},
  },
  mutations: {
    SET_TOP_ROUTERS: (state, routes) => {
      const { topArray, slidObject } = handleRouters(routes || frameIn, [], {});
      state.topRouters = topArray;
      state.topChildSlidRouters = slidObject;
      // console.log(slidObject, 'slidObject');
      // storage.setData(state, 'menu');
    },
    SET_TOP_ACTIVE: (state, preload) => {
      state.activeIndex = preload;
      storage.setData(state, 'menu');
    },
    SET_SLIDER_ACTIVE: (state, preload) => {
      state.activeIndexSlider = preload;
      storage.setData(state, 'menu');
    },

    SET_ROUTES: (state, routes) => {
      state.addRoutes = routes;
      state.routes = frameIn.concat(routes);
    },
    SET_DEFAULT_ROUTES: (state, routes) => {
      state.defaultRoutes = frameIn.concat(routes);
    },
    SET_TOPBAR_ROUTES: (state, routes) => {
      state.topbarRouters = routes;
      storage.setData(state, 'menu');
    },
    SET_SIDEBAR_ROUTERS: (state, preload) => {
      state.isSilder = preload;
      storage.setData(state, 'menu');
    },
    SET_TOP_NAME: (state, preload) => {
      state.topName = preload;
      storage.setData(state, 'menu');
    },
    SET_TOP_PATH: (state, preload) => {
      state.topPath = preload;
      storage.setData(state, 'menu');
    },
    SET_RELOAD_MENU: (state, preload) => {
      if (preload) {
        state.isSilder = preload.isSilder;
        state.topRouters = preload.topRouters;
        state.topChildSlidRouters = preload.topChildSlidRouters;
        state.topName = preload.topName;
        state.topPath = preload.topPath;
        state.activeIndex = preload.activeIndex;
        state.activeIndexSlider = preload.activeIndexSlider;
        storage.setData(state, 'menu');
      }
    },
    SET_USER_INFO: (state, preload) => {
      state.userInfo = preload;
    },
  },
};

export const loadView = (view) => {
  // 路由懒加载
  return (resolve) => require([`@/views/${view}`], resolve);
};
/**
 *
 * // router的name是唯一的
 * **/

const handleRouters = (routes, top, slid, parentRoute = undefined) => {
  let topArray = top;
  let slidObject = slid;
  routes.map((route) => {
    if (parentRoute && parentRoute.meta && parentRoute.meta.level === ROUTESILDE && route.meta.level === ROUTERTOP) {
      throw new Error('路由表嵌套出错');
    }
    if (route.meta && route.meta.title) {
      if (route.meta.level === ROUTERTOP) {
        if (!parentRoute && !route.meta.hideTop) {
          // 没有parentRoute为top一级菜单
          topArray.push({
            ...route,
            children: [],
          });
        } else {
          if (route.meta.level === ROUTERTOP && !route.meta.hideTop) {
            // 拼接头部菜单
            if (topArray.length === 0) {
              topArray.push({
                ...route,
                children: [],
              });
            } else {
              topArray = handleInserTopArray(topArray, route, parentRoute);
              // console.log(topArray, 'handleInserTopArray');
            }
          }
        }
      } else {
        if (parentRoute && parentRoute.meta.level === ROUTERTOP) {
          if (parentRoute.name !== 'home') {
            if (slidObject[parentRoute.name]) {
              slidObject[parentRoute.name] = slidObject[parentRoute.name].concat([
                {
                  ...route,
                  children: handleInserSlidArray(route.children),
                },
              ]);
              // console.log(parentRoute, 'parentRoute', slidObject);
            } else {
              slidObject[parentRoute.name] = [].concat([
                {
                  ...route,
                  children: handleInserSlidArray(route.children),
                },
              ]);
            }
          }
        }
      }
    }
    if (route.children && route.children.length > 0) {
      const result = handleRouters(route.children, topArray, slidObject, route);
      // 更新 topArray 和 slidObject，可以根据具体需求进行合并或其他操作
      topArray = result.topArray;
      slidObject = result.slidObject;
    }
  });
  return {
    topArray,
    slidObject,
  };
};

const handleInserSlidArray = (array, route, parentRoute) => {
  if (!array) {
    return [];
  }
  return array.map((f) => {
    if (f.meta.level === ROUTESILDE) {
      return {
        ...f,
      };
    }
    if (f.children && f.children.length > 0) {
      handleInserSlidArray(f.children, route, parentRoute);
    }
  });
};

const handleInserTopArray = (array, route, parentRoute) => {
  if (route.meta.level === ROUTESILDE) {
    // console.log(array, route, parentRoute, 'array, route, parentRoute');
  }
  if (!parentRoute.name || parentRoute.path === '/') {
    return array.concat([
      {
        ...route,
        children: [],
      },
    ]);
  }
  return array.map((f) => {
    if (f.name === parentRoute.name) {
      f.children.push({
        ...route,
        children: [],
      });
      return {
        ...f,
      };
    }
    if (f.children && f.children.length > 0) {
      handleInserTopArray(f.children, route, parentRoute);
    }
    return {
      ...f,
    };
  });
};

export default permission;
