var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dropdown",
    { staticClass: "layout_user_wrapper", on: { command: _vm.handleCommand } },
    [
      _c(
        "router-link",
        {
          staticClass: "user_link",
          attrs: {
            to: !_vm.userInfo
              ? { name: "login", params: { type: _vm.type } }
              : { name: "user" }
          }
        },
        [
          _c("div", { staticClass: "person" }, [
            _c("div", { staticClass: "person_img" }, [
              _c("img", {
                attrs: {
                  src: !_vm.userInfo
                    ? require("@/assets/images/profilephoto_01a.png")
                    : _vm.userInfo.avatarUrl,
                  alt: ""
                }
              })
            ]),
            _c("div", { staticClass: "person_no_login" }, [
              _c("div", { staticClass: "body" }),
              !_vm.userInfo
                ? _c(
                    "span",
                    {
                      on: {
                        click: function($event) {
                          return _vm.handleLogin("login")
                        }
                      }
                    },
                    [_vm._v("登录")]
                  )
                : _vm._e(),
              !_vm.userInfo
                ? _c(
                    "span",
                    {
                      on: {
                        click: function($event) {
                          return _vm.handleLogin("register")
                        }
                      }
                    },
                    [_vm._v("注册")]
                  )
                : _c("span", [_vm._v("个人中心")])
            ])
          ])
        ]
      ),
      _vm.userInfo
        ? _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c("el-dropdown-item", { attrs: { command: "logout" } }, [
                _vm._v("退出")
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }