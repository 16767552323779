import layoutHeaderAside from '@/layout';
const _import = require('@/utils/util.import');
import { ROUTERTOP } from '../../config/router-level';

export default {
  path: '/personalData/',
  name: 'personal-data',
  component: layoutHeaderAside,
  meta: {
    title: '个人资料',
  },
  children: ((pre) => [
    {
      path: 'basicInformation',
      name: `${pre}basic-information`,
      component: _import('personalData/basic-information'),
      meta: {
        title: '基本信息',
        level: ROUTERTOP,
      },
    },
  ])('personalData-'),
};
