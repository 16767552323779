<template>
  <el-form class="login_form" :model="form" :rules="rules" ref="form">
    <div class="login_title">欢迎登录</div>
    <div class="login_tab">
      <div @click="tabsActive = item.id" :class="tabsActive === item.id ? 'tab is_active ' : 'tab'" v-for="item in tabs" :key="item.id">
        {{ item.title }}
      </div>
    </div>
    <div class="register_role">
      <div class="register_role_title">请选择角色</div>
      <div class="register_role_wrapper">
        <div
          :class="form.identity === item.id ? 'register_role_item is_active' : 'register_role_item'"
          @click="handleChangeRole(item)"
          v-for="item in roles"
          :key="item.id"
        >
          {{ item.label }}
        </div>
      </div>
    </div>
    <div v-if="tabsActive === 1">
      <el-form-item prop="user">
        <el-input v-model="form.user" placeholder="请输入手机号码/邮箱"> </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input show-password v-model="form.password" placeholder="请输入密码" type="password"> </el-input>
      </el-form-item>
    </div>
    <div v-else>
      <el-form-item class="mobile" prop="user">
        <el-input v-model="form.user" placeholder="请输入手机号码">
          <div slot="prepend">
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input v-model="form.password" placeholder="请输入验证码">
          <div slot="suffix" @click="startDuration" class="suffix_code">{{ duration }}</div>
        </el-input>
      </el-form-item>
    </div>
    <div class="opraty">
      <div class="opraty_left" @click="handleChangeMode">
        <span>没有帐号？</span>
        <span>前去注册</span>
      </div>
      <div class="opraty_right" @click="handleReset">
        <span>忘记密码</span>
      </div>
    </div>
    <div class="btn" @click="handleLogin">登录</div>
  </el-form>
</template>

<script>
import { mobileReg, emailReg } from '@/utils/validate.js';
import { login, loginCode, getRegCaptcha } from '@/api/login';
import { Message } from 'element-ui';
export default {
  name: 'LoginForm',
  data() {
    return {
      tabs: [
        {
          title: '账户密码登录',
          id: 1,
        },
        {
          title: '手机验证登录',
          id: 2,
        },
      ],
      roles: [
        {
          id: 1,
          label: '个人',
          value: 1,
        },
        {
          id: 2,
          label: '教练',
          value: 2,
        },
        {
          id: 3,
          label: '团体',
          value: 3,
        },
      ],
      tabsActive: 1,
      form: {
        user: '',
        mobile: '',
        password: '',
        identity: 1,
      },
      durations: -1,
      options: [
        {
          value: '中国+86',
          label: '中国+86',
        },
      ],
      rules: {
        user: [{ required: true, message: '请输入手机号码/邮箱', trigger: 'change' }],
        password: [{ required: true, message: '请输入密码', trigger: 'change' }],
      },
      value: '中国+86',
      duration: '获取验证码',
      isSend: '',
      totalTime: 60,
    };
  },
  watch: {
    tabsActive: function() {
      this.form = {
        user: '',
        mobile: '',
        password: '',
        identity: 1,
      };
    },
  },
  methods: {
    handleChangeMode() {
      this.$emit('changeMode', this.form.user);
    },
    handleReset() {
      this.$emit('resetMode');
    },
    handleChangeRole(item) {
      this.form.identity = item.id;
    },
    //验证码按钮函数
    async startDuration() {
      if (this.isSend) return;
      let result = await getRegCaptcha({ phoneNumber: this.form.user, type: 2, identity: this.form.identity });
      if (result.code !== 0) {
        this.$message.warning(result.msg);
        return;
      }
      this.$message.success(`发送手机验证码成功`);
      this.isSend = true;
      this.duration = this.totalTime + 's后重新发送';
      this.timer = setInterval(() => {
        this.totalTime--;
        this.duration = this.totalTime + 's后重新发送';
        if (this.totalTime < 1) {
          clearInterval(this.timer);
          this.duration = '重新发送验证码';
          this.totalTime = 60;
          this.isSend = false;
        }
      }, 1000);
    },

    async handleLogin() {
      this.form = {
        ...this.form,
        mobile: '',
        email: '',
        username: '',
      };
      if (!this.form.identity) {
        Message.warning('请先选择角色');
        return;
      }
      let accountType = mobileReg.test(this.form.user) ? 'mobile' : emailReg.test(this.form.user) ? 'email' : 'username';
      this.form[accountType] = this.form.user;
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          if (this.tabsActive === 1) {
            let result = await login(this.form);
            if (result.code === 0) {
              // 登录成功
              this.$router.push({
                name: 'index',
              });
            } else if (result.code === 7002) {
              Message.warning(result.msg);
              this.handleChangeMode();
            } else {
              Message.warning(result.msg);
            }
          } else {
            const mobileForm = {
              mobile: '',
              code: '',
              identity: '',
            };
            mobileForm.mobile = this.form.mobile;
            mobileForm.code = this.form.password;
            mobileForm.identity = this.form.identity;
            let result = await loginCode(mobileForm);
            if (result.code === 0) {
              // 登录成功
              this.$router.push({
                name: 'index',
              });
            } else if (result.code === 7002) {
              Message.warning(result.msg);
              this.handleChangeMode();
            } else {
              Message.warning(result.msg);
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
.login_form {
  width: 287px;
  min-height: 313px;
  // margin: 118px auto 0;
  padding: 0 18px;
  box-sizing: border-box;
  transform: translateY(-100px);
  .login_title {
    width: 100%;
    text-align: center;
    height: 29px;
    font-size: 25px;
    color: #000000;
    line-height: 29px;
    letter-spacing: 1px;
    font-style: normal;
    margin-bottom: 34px;
  }
  .login_tab {
    display: flex;
    margin-bottom: 26px;
    .tab {
      flex: 1 1 50%;
      text-align: center;
      height: 29px;
      font-size: 13px;
      color: #333333;
      line-height: 29px;
      font-style: normal;
      cursor: pointer;
    }
    .is_active {
      color: #0d65da;
      transition: 0.4s;
    }
  }
  .register_role {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .register_role_title {
      height: 18px;
      font-size: 13px;
      color: #999999;
      line-height: 18px;
      font-style: normal;
    }

    .register_role_wrapper {
      display: flex;
      margin-top: 11px;
      width: 100%;
      justify-content: space-around;
      margin-bottom: 22px;
      .register_role_item {
        width: 72px;
        height: 27px;
        background: #ffffff;
        border-radius: 22px;
        border: 1px solid #acd0ff;
        font-size: 13px;
        color: #3589f6;
        line-height: 27px;
        font-style: normal;
        text-align: center;
        cursor: pointer;
      }
      .is_active {
        background: #3589f6;
        color: #ffffff;
      }
    }
  }
  .el-form-item {
    margin-bottom: 27px;
  }
  .el-input {
    // width: 251px;

    .el-input__inner {
      height: 32px;
      background: #ffffff;
      line-height: 32px;
      border-radius: 22px;
      border: 1px solid #cecece;
    }
  }
  .opraty {
    margin-bottom: 22px;
    height: 16px;
    font-size: 13px;
    color: #999999;
    line-height: 16px;
    font-style: normal;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    .opraty_left {
      span {
        &:nth-last-child(1) {
          color: #0d65da;
        }
      }
    }
    .opraty_right {
      span {
        color: #0d65da;
      }
    }
  }
  .btn {
    height: 39px;
    line-height: 39px;
    background: linear-gradient(270deg, #66a9ff 0%, #3589f6 100%);
    border-radius: 4px;
    font-size: 16px;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
  }
  .suffix_code {
    color: #0d65da;
    margin-right: 16px;
    font-size: 13px;
    cursor: pointer;
  }
  .mobile {
    .el-input__inner {
      // height: 32px;
      background: #ffffff;
      border-radius: 0 22px 22px 0;
      border: 1px solid #cecece;
    }
    .el-input-group__prepend {
      background: rgba(255, 255, 255, 1);
      border-radius: 22px 0 0 22px;
    }
    .el-select {
      width: 100px;
      font-size: 13px;
      font-style: normal;
      color: #000;
      .el-input__icon {
        line-height: 32px;
      }
    }
  }
}
</style>
