<template>
  <div class="more_wrapper">
    <!-- <div :class="index % 2 ? 'more_item dark' : 'more_item'" v-for="(item, index) in 10" :key="item">
      <div class="item_img">
        <img src="@/assets/images/Sslogo_01.png" alt="" />
      </div>
      <div class="item_title">长空之战赛事</div>
    </div> -->
    <div class="more_item cursor" v-for="item in dataList" :key="item.id" @click="goDetail(item.url)">
      <div class="item_img">
        <img :src="item.img" alt="" />
      </div>
      <div class="title-box">
        <div class="item_title">{{ item.title1 }}</div>
        <div class="item_title">{{ item.title2 }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'More',
  data() {
    return {
      dataList: [
        {
          id: 1,
          title1: '国家体育总局航空',
          title2: ' 无线电模型运动管理中心',
          img: require('@/assets/images/Sslogo_02.png'),
          target: '',
          url: 'https://www.sport.gov.cn/hgzx/n14836/index.html',
        },
        {
          id: 2,
          title1: '中国航空运动协会',
          title2: '',
          img: require('@/assets/images/Sslogo_03.png'),
          target: '',
          url: 'http://www.asfc.org.cn/fxzdh/',
        },
        {
          id: 3,
          title1: '全国青少年',
          title2: '模拟飞行锦标赛',
          img: require('@/assets/images/Sslogo_04.png'),
          target: '',
          url: 'http://www.asfcyy.com',
        },
        {
          id: 4,
          title1: '全国青少年',
          title2: '航空航天模型教育竞赛',
          img: require('@/assets/images/Sslogo_05.png'),
          target: '',
          url: 'http://www.asfc.org.cn/dynamic/announcement/2024/0402/599519.html',
        },
        {
          id: 5,
          title1: '中国国际飞行器',
          title2: '设计挑战赛',
          img: require('@/assets/images/Sslogo_011.png'),
          target: '',
          url: 'http://cadc.sport.org.cn/',
        },
      ],
    };
  },
  methods: {
    goDetail(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.cursor {
  cursor: pointer;
}
.more_wrapper {
  width: 100%;
  height: 274px;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  padding: 30px 60px 0 60px;
  justify-content: space-around;
  box-sizing: border-box;
  .more_item {
    width: 160px;
    height: 100%;
    // background: linear-gradient(180deg, #ffffff 0%, #efefef 100%);
    // padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:nth-child(1) {
      border-radius: 10px 0px 0px 10px;
    }
    &:nth-child(6n) {
      border-radius: 10px 0px 0px 10px;
    }
    &:nth-child(5n) {
      border-radius: 0px 10px 10px 0px;
    }
    .item_img {
      width: 160px;
      height: 160px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .title-box {
      height: 36px;
      margin-top: 10px;
    }
    .item_title {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #333333;
      text-align: center;
      font-style: normal;
    }
  }
  .dark {
    background: linear-gradient(180deg, #c9d3dd 0%, #9cabb9 100%);
  }
}
</style>
