<template>
  <div :class="type === 'dark' ? 'page_card_wrapper_dark' : 'page_card_wrapper'">
    <div class="page_card_title">{{ title }}</div>
    <slot class="desc" name="desc"></slot>
    <slot name="content" />
  </div>
</template>
<script>
export default {
  name: 'PageCard',
  props: {
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'light',
    },
  },
};
</script>
<style lang="scss" scoped>
.page_card_wrapper,
.page_card_wrapper_dark {
  width: 100%;
  // min-height: 500px;
  .page_card_title {
    width: 100%;
    height: 32px;
    font-family: MicrosoftYaHei;
    font-size: 28px;
    color: #333333;
    line-height: 32px;
    text-align: center;
    font-style: normal;
    margin-bottom: 14px;
  }
  .desc {
    margin-bottom: 26px;
  }
}
.page_card_wrapper_dark {
  .page_card_title {
    color: #fff;
  }
}
</style>
