var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class:
        _vm.$router.app._route.name === "index"
          ? "layout-wrapper index-wrapper"
          : "layout-wrapper"
    },
    [
      _c(
        "div",
        { class: _vm.navBarFixed == true ? "navBarWrap" : "" },
        [_c("Header")],
        1
      ),
      _c("Session"),
      _c("Footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }