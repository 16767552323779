var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", [
      _c(
        "div",
        {
          directives: [{ name: "drag", rawName: "v-drag" }],
          staticClass: "content"
        },
        [
          _c(
            "el-tooltip",
            {
              attrs: {
                "popper-class": "tooltip-class",
                effect: "light",
                placement: "left"
              }
            },
            [
              _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                _c("img", {
                  staticClass: "wxCode",
                  attrs: {
                    src: require("@/assets/images/bottomLogo1.jpg"),
                    alt: ""
                  }
                })
              ]),
              _c("img", {
                staticClass: "img1",
                attrs: {
                  src: require("../../assets/images/pop_01.png"),
                  alt: ""
                }
              })
            ]
          ),
          _c(
            "el-tooltip",
            {
              attrs: {
                "popper-class": "tooltip-class",
                effect: "light",
                placement: "left"
              }
            },
            [
              _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                _vm._v("联系电话：18612833500")
              ]),
              _c("img", {
                staticClass: "img1",
                attrs: {
                  src: require("../../assets/images/pop_02.png"),
                  alt: ""
                }
              })
            ]
          ),
          _vm.showBtn
            ? _c("img", {
                staticClass: "img2",
                attrs: {
                  src: require("../../assets/images/pop_03.png"),
                  alt: ""
                },
                on: { click: _vm.scrollToTop }
              })
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }