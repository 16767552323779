export default (function() {
  const locales = {};

  locales.id = 'zh_CN';

  const messages = {};
  locales.MESSAGES = messages;

  const fields = {};
  locales.FIELDS = fields;

  //步骤条状态
  messages['resultState'] = {
    '0': 'success',
    '1': 'working',
    '2': 'success',
    '3': 'success',
    '4': 'error',
  };
  return locales;
})();
