<template>
  <div class="event_infomation_card_wrapper">
    <div class="event_infomation_card" v-for="(item, index) in list" :key="item.id">
      <div class="event_infomation_card_left">
        <img :src="imgList[index].url" alt="" />
      </div>
      <div class="event_infomation_card_right">
        <div class="event_infomation_card_title pointer" @click="goDetail(item)">{{ item.title }}</div>
        <div class="event_infomation_card_bototm">
          <div class="event_infomation_card_time">报名时间</div>
          <div class="event_infomation_card_time_value">{{ item.registrationBeginTime }} -- {{ item.registrationEndTime }}</div>
          <div @click="handlePush(item)" :class="'event_infomation_card_btn ' + handleClassets(item)">{{ btnLabel | btnLabelFilter(item) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { indexList } from '@/api/index';
import { mapGetters } from 'vuex';
export default {
  name: 'EventInfomationCard',
  filters: {
    btnLabelFilter: (a, val) => {
      if (new Date(val.registrationBeginTime).getTime() > new Date().getTime()) {
        return '即将报名';
      } else if (
        new Date(val.registrationBeginTime).getTime() <= new Date().getTime() &&
        new Date().getTime() <= new Date(val.registrationEndTime).getTime()
      ) {
        return '报名';
      } else {
        return '已结束';
      }
    },
  },
  data() {
    return {
      list: [],
      imgList: [
        { url: require('@/assets/images/ShBK_01.png') },
        { url: require('@/assets/images/ShBK_02.png') },
        { url: require('@/assets/images/ShBK_03.png') },
        { url: require('@/assets/images/ShBK_04.png') },
        { url: require('@/assets/images/ShBK_05.png') },
        { url: require('@/assets/images/ShBK_06.png') },
      ],
    };
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  mounted() {
    this.getInfoList();
  },
  methods: {
    async getInfoList() {
      let result = await indexList();
      this.list = result?.data;
    },
    handleClassets(item) {
      if (new Date(item.registrationBeginTime).getTime() > new Date().getTime()) {
        return 'will';
      } else if (
        new Date(item.registrationBeginTime).getTime() <= new Date().getTime() &&
        new Date().getTime() <= new Date(item.registrationEndTime).getTime()
      ) {
        return 'ing';
      } else {
        return 'exprise';
      }
    },
    goDetail(item) {
      this.$router.push({
        path: '/eventinformationDetail',
        query: {
          id: item.id,
        },
      });
    },
    handlePush(item) {
      if (!this.userInfo) {
        this.$router.push({
          path: '/login',
        });
      } else if (
        new Date(item.registrationBeginTime).getTime() <= new Date().getTime() &&
        new Date().getTime() <= new Date(item.registrationEndTime).getTime()
      ) {
        if ((this.userInfo.identity === 1 && item.raceType === 0) || (this.userInfo.identity === 3 && item.raceType === 1)) {
          this.$router.push({
            path: '/user/game/bm',
            query: {
              item,
            },
          });
        } else if (this.userInfo.identity === 2) {
          this.$message.warning({
            message: '教练暂不可报名，请切换账号',
            type: 'warning',
            customClass: 'message-zindex',
          });
        } else {
          this.$message.warning({
            message: '身份类型和赛事类型不匹配，请切换账号',
            type: 'warning',
            customClass: 'message-zindex',
          });
        }
      } else {
        this.$router.push({
          path: '/eventinformationDetail',
          query: {
            id: item.id,
          },
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
//设置message层级防止被遮挡
.message-zindex {
  z-index: 10000 !important;
}
.event_infomation_card_wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .event_infomation_card {
    width: 380px;
    height: 200px;
    display: flex;
    background: linear-gradient(180deg, #ffffff 0%, #efefef 100%);
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    margin-right: 30px;
    margin-bottom: 30px;
    border-radius: 10px;
    &:nth-child(3n) {
      margin-right: 0px;
    }
    .event_infomation_card_left {
      height: 100%;
      width: 120px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .event_infomation_card_right {
      flex: 1;
      padding: 16px 22px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .event_infomation_card_title {
        height: 48px;
        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-weight: bold;
        font-size: 18px;
        color: #333333;
        line-height: 24px;
        text-align: left;
        font-style: normal;
      }
      .event_infomation_card_time {
        height: 19px;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #999999;
        line-height: 19px;
        text-align: left;
        font-style: normal;
        margin-bottom: 6px;
        // margin-top: 36px;
      }
      .event_infomation_card_time_value {
        height: 20px;
        font-family: ArialMT;
        font-size: 16px;
        color: #dd773a;
        line-height: 21px;
        text-align: left;
        margin-bottom: 12px;
        font-style: normal;
      }
      .event_infomation_card_btn {
        height: 32px;
        background: linear-gradient(270deg, #a4c8dc 0%, #96b2d6 100%);
        border-radius: 4px;
        font-family: MicrosoftYaHei;
        font-size: 16px;
        color: #ffffff;
        line-height: 32px;
        text-align: center;
        font-style: normal;
        align-items: flex-end;
        cursor: pointer;
      }
      .ing {
        background: linear-gradient(270deg, #f2982c 0%, #de783a 100%);
      }
      .exprise {
        background: linear-gradient(270deg, #cdcdcd 0%, #c1c1c1 100%);
      }
    }
  }
}
</style>
