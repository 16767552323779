var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-home" }, [
    _c(
      "div",
      { staticClass: "swiper_wrapper" },
      [
        _c(
          "el-carousel",
          { on: { change: _vm.getIndex } },
          _vm._l(_vm.bannerList, function(item) {
            return _c("el-carousel-item", { key: item }, [
              _c(
                "div",
                {
                  staticClass: "swiper",
                  style: {
                    background: `url('${item.picture}') no-repeat center`,
                    backgroundSize: "cover",
                    height: "100%"
                  }
                },
                [
                  _c("div", { staticClass: "content" }, [
                    _c("div", { staticClass: "swpiper-title" }, [
                      _vm._v(_vm._s(item.title))
                    ]),
                    _c("div", {
                      staticClass: "swpiper-desc",
                      domProps: { innerHTML: _vm._s(item.info) }
                    }),
                    _c("div", { staticClass: "swpiper-time" }, [
                      _vm._v(
                        "报名时间：" +
                          _vm._s(item.beginTime) +
                          " -- " +
                          _vm._s(item.endTime)
                      )
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "button cursor",
                        on: {
                          click: function($event) {
                            return _vm.goBm(item)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.statusType == 1
                              ? "报名中"
                              : _vm.statusType == 2
                              ? "立即预约"
                              : "已结束"
                          )
                        )
                      ]
                    )
                  ])
                ]
              )
            ])
          }),
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "page_content" },
      [
        _c("BannerBar"),
        _c("PageCard", {
          staticStyle: { "margin-top": "33px" },
          attrs: { title: "赛事信息" },
          scopedSlots: _vm._u([
            {
              key: "desc",
              fn: function() {
                return [
                  _c("div", { staticClass: "desc" }, [
                    _c("span", [_vm._v("从报名到证书查询，助力冲上云霄。")]),
                    _c(
                      "span",
                      {
                        staticStyle: { cursor: "pointer" },
                        on: {
                          click: function($event) {
                            return _vm.handlePush("eventinformation")
                          }
                        }
                      },
                      [_vm._v("查看更多赛事信息 >>")]
                    )
                  ])
                ]
              },
              proxy: true
            },
            {
              key: "content",
              fn: function() {
                return [_c("EventInformationCard")]
              },
              proxy: true
            }
          ])
        }),
        _c(
          "div",
          { staticClass: "aviation_consulting" },
          [
            _c("div", { staticClass: "body" }),
            _c("PageCard", {
              staticClass: "aviation_consulting_card",
              attrs: { title: "航赛资讯" },
              scopedSlots: _vm._u([
                {
                  key: "desc",
                  fn: function() {
                    return [
                      _c("div", { staticClass: "desc" }, [
                        _c("span", [_vm._v("随足不出户了解相关信息，")]),
                        _c(
                          "span",
                          {
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function($event) {
                                return _vm.handlePush("aviationcompetition")
                              }
                            }
                          },
                          [_vm._v("查看全部航赛资讯 >>")]
                        )
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "content",
                  fn: function() {
                    return [_c("AvaiationConsulting")]
                  },
                  proxy: true
                }
              ])
            })
          ],
          1
        ),
        _c("PageCard", {
          staticClass: "page_card_teacher",
          attrs: { title: "飞行榜单" },
          scopedSlots: _vm._u([
            {
              key: "desc",
              fn: function() {
                return [
                  _c("div", { staticClass: "desc" }, [
                    _c("span", [_vm._v("学习你追我赶，成绩再创辉煌。")]),
                    _c(
                      "span",
                      {
                        staticClass: "pointer",
                        on: {
                          click: function($event) {
                            return _vm.handlePush("flightRanking")
                          }
                        }
                      },
                      [_vm._v("查看更多飞行榜单 >>")]
                    )
                  ])
                ]
              },
              proxy: true
            },
            {
              key: "content",
              fn: function() {
                return [_c("FlightRankingSwiper")]
              },
              proxy: true
            }
          ])
        }),
        _c("PageCard", {
          staticClass: "page_card_teacher",
          attrs: { title: "认证名师" },
          scopedSlots: _vm._u([
            {
              key: "desc",
              fn: function() {
                return [
                  _c("div", { staticClass: "desc" }, [
                    _c("span", [_vm._v("名师汇集，保证教学质量与学习效果。")]),
                    _c(
                      "span",
                      {
                        staticClass: "pointer",
                        on: {
                          click: function($event) {
                            return _vm.handlePush("certifiedmaster")
                          }
                        }
                      },
                      [_vm._v("查看更多认证名师 >>")]
                    )
                  ])
                ]
              },
              proxy: true
            },
            {
              key: "content",
              fn: function() {
                return [_c("TeacherSwiper")]
              },
              proxy: true
            }
          ])
        }),
        _c(
          "div",
          { staticClass: "school_wrapper" },
          [
            _c("div", { staticClass: "body" }),
            _c("PageCard", {
              staticClass: "school_wrapper_pageCard",
              staticStyle: { "margin-top": "60px", float: "left" },
              attrs: { type: "dark", title: "航校风采" },
              scopedSlots: _vm._u([
                {
                  key: "desc",
                  fn: function() {
                    return [
                      _c("div", { staticClass: "desc" }, [
                        _c("span", [_vm._v("航校风采尽情展示。")]),
                        _c(
                          "span",
                          {
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function($event) {
                                return _vm.handlePush("schoolstyle")
                              }
                            }
                          },
                          [_vm._v("查看更多航校信息 >>")]
                        )
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "content",
                  fn: function() {
                    return [_c("SchoolSwiper")]
                  },
                  proxy: true
                }
              ])
            })
          ],
          1
        ),
        _c("PageCard", {
          staticStyle: {
            "margin-top": "60px",
            float: "left",
            "padding-bottom": "60px",
            "box-sizing": "border-box"
          },
          attrs: { title: "更多赛事" },
          scopedSlots: _vm._u([
            {
              key: "desc",
              fn: function() {
                return [
                  _c("div", { staticClass: "desc" }, [
                    _c("span", { staticStyle: { color: "#333" } }, [
                      _vm._v("更多相关赛事，助力冲上云霄。")
                    ])
                  ])
                ]
              },
              proxy: true
            },
            {
              key: "content",
              fn: function() {
                return [_c("More")]
              },
              proxy: true
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }