<template>
  <div class="privacy-policy">
    <p>
      一、前言
    </p>
    <p>
      感谢您选择我们的网站。我们非常重视您的隐私和个人信息的保护。本隐私协议旨在明确我们收集、使用、存储、共享和保护您个人信息的政策与措施。请您在使用我们的网站之前，仔细阅读本隐私协议，并同意其内容。
    </p>
    <p>
      二、信息收集
    </p>
    <p>
      1.当您访问我们的网站时，我们可能会自动收集关于您设备的信息，包括IP地址、浏览器类型、访问时间等，以便我们更好地了解用户行为，优化网站性能。
    </p>
    <p>
      2.当您在我们的网站上注册账户、提交表单或参与互动功能时，我们可能需要您提供姓名、联系方式、邮箱、地址等个人信息。这些信息将用于为您提供相应的服务或与您进行沟通。
    </p>
    <p>
      3.若您参与本网站提供的活动或赛事，我们可能需要您提供与活动或赛事相关的特定信息。
    </p>
    <p>
      三、信息使用
    </p>
    <p>
      1.我们将根据您提供的信息为您提供相应的服务，包括但不限于赛事通知、赛事报名、成绩公布、课程报名、课程安排、售后服务等。
    </p>
    <p>
      2.我们可能会使用您的个人信息向您发送有关赛事信息、报名审核信息、成绩公布、课程信息、活动通知、促销优惠等相关信息。
    </p>
    <p>
      3.我们可能将您的个人信息用于市场调研、数据分析等目的，以改进我们的产品和服务。
    </p>
    <p>
      四、信息共享
    </p>
    <p>
      1.我们不会将您的个人信息出售给任何第三方。
    </p>
    <p>
      2.仅在必要情况下，我们可能会与合作伙伴、服务供应商等共享您的个人信息，以便完成您请求的服务或实现我们的业务目标。在此情况下，我们将确保合作伙伴和服务供应商遵守相关的隐私政策和法规，并对您的个人信息进行保密处理。
    </p>
    <p>
      五、信息安全
    </p>
    <p>
      1.我们将采取合理的物理、电子和管理措施来保护您的个人信息，防止未经授权的访问、使用、泄露或损坏。
    </p>
    <p>
      2.我们将定期对数据进行备份，以防数据丢失或损坏。
    </p>
    <p>
      六、信息披露条件
    </p>
    <p>
      1.除非法律要求或经您明确同意，我们不会将您的个人信息披露给任何第三方。
    </p>
    <p>
      2.在以下情况下，我们可能需要披露您的个人信息: 法律程序或政府监管要求; 遵守相关法规、法律程序或政府强制要求; 保护我们或他人的合法权益。
    </p>
    <p>七、跨境数据流动</p>
    <p>1.若因业务需求，需要将您的个人信息传输至境外，我们将确保该传输符合法律法规的规定，并采取相应的安全措施。</p>
    <p>2.在跨境数据传输过程中，我们将与接收方签订协议，明确数据保护责任和要求，确保您的个人信息得到妥善保护。</p>
    <p>八、权利与选择</p>
    <p>1.您有权要求我们更新、更正或删除您的个人信息。</p>
    <p>2.您有权拒绝我们对您个人信息的部分使用或共享。</p>
    <p>3.若您希望行使上述权利，可以通过本网站提供的联系方式与我们取得联系。</p>
    <p>九、儿童隐私</p>
    <p>我们的网站和服务不针对18周岁以下的儿童。如果您是儿童，请在得到家长或监护人的许可后使用我们的网站和服务。</p>
    <p>十、变更与更新</p>
    <p>
      我们可能会根据实际情况对本隐私协议进行变更或更新。在变更或更新后，我们将通过网站公告或其他适当方式通知您。请您定期查看本隐私协议，以便及时了解最新的政策与措施。
    </p>
    <p>十一、联系方式</p>
    <p>如果您对本隐私协议有任何疑问或建议，请通过以下方式与我们联系：[联系我们]。</p>
    <p>十二、法律适用与管辖</p>
    <p>
      本隐私协议的订立、执行和解释以及争议的解决均应适用[国家/地区]法律。如发生任何争议，双方应首先友好协商解决；协商不成的，任何一方均有权向[国家/地区]有管辖权的人民法院提起诉讼。
    </p>
    <p>
      请仔细阅读本隐私协议，并确保您完全理解其内容和含义。如您不同意本隐私协议的任何条款，请勿使用我们的网站和服务。使用我们的网站和服务即表示您已充分阅读、理解并同意本隐私协议的所有条款。
    </p>
    <p>感谢您的信任与支持，我们将竭诚为您提供优质、安全的服务。</p>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  // 可以添加数据和方法
};
</script>

<style scoped>
.privacy-policy {
  max-width: 960px;
}
</style>
