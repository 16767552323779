var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    {
      class:
        _vm.$router.app._route.name === "index"
          ? "layout-header"
          : "layout-header fixed"
    },
    [
      _c(
        "div",
        { staticClass: "container" },
        [_c("Logo"), _c("Nav", { staticClass: "nav" }), _c("User")],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }