<template>
  <div class="nodata">
    <div v-show="list && list.length > 0">
      <slot></slot>
    </div>
    <div class="list-no-data" :style="{ '--heightLine': height + 'px' }" v-show="list == null || (list && list.length === 0)">
      <div class="content">
        <img src="@/assets/images/icon_nodata@2x.png" alt="暂无数据" />
        <div class="no-text">暂无数据</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'nodata',
  props: {
    //数据数组
    list: {
      required: true,
    },
    //标题
    subTitle: {
      type: String,
      default: '暂无数据，请先点击查询',
    },
    height: {
      type: Number,
      default: 600,
    },
  },
};
</script>

<style lang="scss">
.nodata {
  // height: 500px;
  .list-no-data {
    height: var(--heightLine);
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    color: #848bad;
    margin-top: 60px;
    padding-bottom: 30px;
    img {
      width: 200px;
      height: 200px;
    }
    .no-text {
      font-weight: 400;
      font-size: 16px;
      color: #90a8c0;
      padding-top: 14px;
      font-style: normal;
    }
  }
}
</style>
