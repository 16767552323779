// import { dateUtil } from '@csii/vx-util';
import locale from '@/config/locale.js';
export const mobileReg = /^1[0-9]{10}$/;

export const address = (val) => {
  const value = val.trim();
  return value.length >= 5 && value.length <= 100;
};

export const userName = /^[a-zA-Z0-9_\u4e00-\u9fa5]{3,20}$/;

export const emailReg = /^(\w)+(\.\w+)*@(\w)+((\.\w+)+)$/;

// 转化日期为年月日...
const changeDateToText = function(date) {
  const dateType = date.match(/[yMwdhms]/)[0];
  return locale.MESSAGES.dateTypeDict[dateType];
};

// const yesterdayFun = (time) => {
//   // return dateUtil.changeDate('-1d', time, 'YYYY-MM-DD');
// };
// eslint-disable-next-line no-unused-vars
const dateSpanFun = (dateSpan, dateSpanFix, callback, value) => {
  if (!/^(-?\d+)([yMwdhms]s?)$/.test(dateSpan)) {
    console.error('请输入正确的时间跨度, 详情请查看changeDate()方法');
  } else {
    let span = parseInt(dateSpan.match(/-?\d+/g)[0] || 0, 10);
    span = span > 0 ? span : 0 - span;
    const spanText = changeDateToText(dateSpan);
    let ifValue = '';
    if (dateSpan.includes('M') || dateSpan.includes('y')) {
      // ifValue =
      //   span > 0
      //     ? dateUtil.daysBetween(yesterdayFun(value[1]) || yesterdayFun(value), value[0] || new Date(), spanText.value) > span - 1
      //     : dateUtil.daysBetween(yesterdayFun(value[1]) || yesterdayFun(new Date()), value[0] || yesterdayFun(value), spanText.value) > span - 1;
    } else {
      // ifValue =
      //   span > 0
      //     ? dateUtil.daysBetween(value[1] || value, value[0] || new Date(), spanText.value) > span - 1
      //     : dateUtil.daysBetween(value[1] || new Date(), value[0] || value, spanText.value) > span - 1;
    }
    const callbackText = `单次查询的时间跨度不得超过${span}个${spanText.text}`;
    dateFun(ifValue, dateSpanFix, spanText, callback, span, callbackText);
  }
};
// eslint-disable-next-line no-unused-vars
const dateRangeFun = (dateRange, dateRangeFix, callback, _value) => {
  if (!/^(-?\d+)([yMwdhms]s?)$/.test(dateRange)) {
    console.error('请输入正确的可操作时间长度, 详情请查看changeDate()方法');
  } else {
    const range = parseInt(dateRange.match(/-?\d+/g)[0] || 0, 10);
    const rangeText = changeDateToText(dateRange);
    const ifValue = undefined;
    // const ifValue =
    //   range < 0
    //     ? dateUtil.daysBetween(new Date(), value[0], rangeText.value) > 0 - range - 1
    //     : dateUtil.daysBetween(value[1], new Date(), rangeText.value) > range - 1;
    const callbackText = `系统可提供近${range}${rangeText.text}内的结果查询`;
    dateFun(ifValue, dateRangeFix, rangeText, callback, range < 0 ? 0 - range : range, callbackText);
  }
};
const dateFun = (ifValue, dateRange, text, callback, span, callbackText) => {
  if (ifValue) {
    return dateRange ? callback(new Error(dateRange)) : callback(new Error(callbackText));
  }
};
export default {
  // 勾选协议校验
  checkBoxRule: function(rule, value, callback) {
    const prefix = rule.prefix || '协议';
    if (value) {
      return callback();
    } else {
      return callback(new Error(`请勾选${prefix}`));
    }
  },
  // 100的整数倍校验
  integralMultipleRule: function(rule, value, callback) {
    const prefix = rule.prefix || '购买份数';
    const number = Number(rule.num) || 100;
    if (value % number !== 0) {
      return callback(new Error(`${prefix}必须是${number}的整数倍`));
    } else {
      return callback();
    }
  },
  // 日期范围
  dateRangeRule: function(rule, value, callback) {
    const dateSpan = rule.dateSpan || null; // 时间跨度
    const dateSpanFix = rule.dateSpanFix; // 时间跨度 - 不符合的提示文字
    const dateRange = rule.dateRange || null; // 可以操作的最长时间
    const dateRangeFix = rule.dateRangeFix; // 可以操作的最长时间 - 不符合的提示文字
    if (value) {
      dateSpan && dateSpanFun(dateSpan, dateSpanFix, callback, value);
      dateRange && dateRangeFun(dateRange, dateRangeFix, callback, value);
    }
    return callback();
  },
  // 金额校验方法
  moneyRule: function(rule, value, callback) {
    const prefix = rule.prefix || '金额';
    const minAmount = rule.minAmount || (rule.minAmounts && rule.minAmounts()) || 0;
    const customAmount = rule.customAmount && rule.customAmount();
    const reg = /^\d{1,12}(\.\d{0,2})?$/;
    let invalidMsg = '';
    if (value) {
      if (value === '.') {
        invalidMsg = `${prefix}格式有误，请重新输入！`;
      }
      if (parseFloat(value) < parseFloat(minAmount)) {
        invalidMsg = `${prefix}不能小于${minAmount}元`;
      }
      if (parseFloat(value) > parseFloat(customAmount)) {
        invalidMsg = `${prefix}不能大于${rule.customAmountPrefix}`;
      }
      if (+value === 0) {
        invalidMsg = `${prefix}不能为0`;
      }
      if (!reg.test(value)) {
        invalidMsg = `${prefix}格式有误，小数点前最高12位！`;
      }
      return invalidMsg ? callback(new Error(invalidMsg)) : callback();
    } else {
      return callback();
    }
  },
  // 校验数字类型
  checkCountRule: function(rule, value, callback) {
    const prefix = rule.prefix;
    const regu = /^[1-9]\d*$/;
    if (value && !regu.test(value)) {
      return callback(new Error(`${prefix}格式有误，请重新输入！`));
    } else {
      return callback();
    }
  },
  // 校验中文名字拼音
  chineseName: function(rule, value, callback) {
    const regu = /^[A-Z]+$/;
    if (value && !regu.test(value)) {
      return callback(new Error(`格式有误，请输入大写英文字母！`));
    } else {
      return callback();
    }
  },
  // 限制输入60个字符或者30个汉字
  checkContentRule: function(rule, value, callback) {
    const regu = /^[\u4e00-\u9fa5]{1,30}$|^[\dA-Za-z_]{1,60}$/;
    if (value && !regu.test(value)) {
      return callback(new Error(`请输入60个字符或者30个汉字！`));
    } else {
      return callback();
    }
  },
  // 用户名不能包含非法字符
  checkAcNameRule: function(rule, value, callback) {
    const prefix = rule.prefix || '用户名';
    const regu = /[`~!#$%^&*()_\\+-=<>?:"{}|~！#￥%……&*（）={}|《》？：“”【】、；‘’，。、\s+]/;
    if (value && regu.test(value)) {
      return callback(new Error(`${prefix}格式有误，请重新输入！`));
    } else {
      return callback();
    }
  },
  // 别名只能输入数字，字母，汉字
  checkNameRule: function(rule, value, callback) {
    const prefix = rule.prefix || '用户名';
    const regu = /^[A-Za-z0-9\u4e00-\u9fa5]+$/;
    if (value && !regu.test(value)) {
      return callback(new Error(`${prefix}格式有误，请重新输入！`));
    } else {
      return callback();
    }
  },
  // 利率
  rateCountRule: function(rule, value, callback) {
    const prefix = rule.prefix || '利率';
    const regu = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/;
    if (value && !regu.test(value)) {
      return callback(new Error(`${prefix}格式有误，请重新输入！`));
    } else {
      return callback();
    }
  },
  //小于等于30%，并保留两位小数的利率
  rateRule: function(rule, value, callback) {
    const prefix = rule.prefix || '利率';
    const regu = /^(([1-9]|[12][0-9]|30)|(([1-9]|[12][0-9])\.[0-9][0-9]{1})|(0\.(0[1-9]|[1-9][0-9]{1}))|(0\.0{2})|(30\.0{2}))$/;
    if (value && !regu.test(value)) {
      return callback(new Error(`${prefix}格式有误，请重新输入0-30之间的保留两位小数的年利率！`));
    } else {
      return callback();
    }
  },
  // 校验手机号
  phoneRule: function(rule, value, callback) {
    const prefix = rule.prefix || '手机号码';
    const regu = /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/;
    if (value && !regu.test(value)) {
      return callback(new Error(`${prefix}格式有误，请重新输入！`));
    } else {
      return callback();
    }
  },
  // 校验座机
  landlineRule: function(rule, value, callback) {
    const prefix = rule.prefix || '座机号码';
    const regu = /\d{3}-\d{8}|\d{4}-\d{7}/;
    if (value && !regu.test(value)) {
      return callback(new Error(`${prefix}格式有误，请重新输入！`));
    } else {
      return callback();
    }
  },
  // 校验身份证号
  idCardRule: function(rule, value, callback) {
    const prefix = rule.prefix || '身份证号码';
    const regu = /(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0[1-9]|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/;
    if (value && !regu.test(value)) {
      return callback(new Error(`您输入的${prefix}格式有误，请重新输入！`));
    } else {
      return callback();
    }
  },
  // 校验email
  emailRule: function(rule, value, callback) {
    const prefix = rule.prefix || 'e-mail';
    const reg = /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/;
    if (value && !reg.test(value)) {
      return callback(new Error(`${prefix}格式有误，请重新输入！`));
    } else {
      return callback();
    }
  },
  // 校验账号
  accountRule: function(rule, value, callback) {
    const prefix = rule.prefix || '账户';
    const regu = /^\d{16,}$/;
    if (value && !regu.test(value)) {
      return callback(new Error(`${prefix}格式有误，请重新输入！`));
    } else {
      return callback();
    }
  },
  // 校验6位数字
  verCodeRule: function(rule, value, callback) {
    const prefix = rule.prefix || '验证码';
    const num = rule.num || 6;
    const regu = eval('/^\\d{' + num + '}$/');
    const reg = /^[0-9]+.?[0-9]*$/;
    if (value) {
      if (!reg.test(value)) {
        return callback(new Error(`短信验证码格式错误，请重新输入！`));
      } else if (!regu.test(value)) {
        return callback(new Error(`${prefix}不足6位，请重新输入！`));
      } else {
        return callback();
      }
    } else {
      return callback(new Error(`请输入${num}位数字${prefix}`));
    }
  },
  // 校验姓名
  nameRule: function(rule, value, callback) {
    const prefix = rule.prefix || '姓名';
    const regu = /^[\u4E00-\u9FA5]{2,4}$/;
    if (value && !regu.test(value)) {
      return callback(new Error(`您输入的${prefix}格式有误，请重新输入！`));
    } else {
      return callback();
    }
  },
  // 校验邮政编码
  postCodeRule: function(rule, value, callback) {
    const prefix = rule.prefix || '邮政编码';
    const regu = /^[0-9]{6}$/;
    if (!value || value === null || !regu.test(value)) {
      return callback(new Error(`${prefix}格式有误，请重新输入！`));
    } else {
      return callback();
    }
  },
  // 校验只能输入英文/拼音
  onlyPYRule: function(rule, value, callback) {
    const prefix = rule.prefix || '英文/拼音';
    const regu = /[^A-Z]/;
    if (value && regu.test(value)) {
      return callback(new Error(`${prefix}格式有误，请重新输入！`));
    } else {
      return callback();
    }
  },
  //校验地址不能输入特殊字符
  onlyHZRule: function(rule, value, callback) {
    const prefix = rule.prefix || '地址';
    const regu = /[`!@#$%^&*()_+,.<>?:"{}|~！@#￥%……&*]/;
    if (value && regu.test(value)) {
      return callback(new Error(`${prefix}格式有误，请重新输入！`));
    } else {
      return callback();
    }
  },
  // 处理密码控件form提交不做校验，通过自身方法校验
  pwdPass: function(rule, value, callback) {
    return callback();
  },
  // 处理ukeyform提交不做校验，通过自身方法校验
  ukeyRule: function(rule, value, callback) {
    return callback();
  },
  // 校验3或5位数字
  cifNoRule: function(rule, value, callback) {
    const prefix = rule.prefix || '';
    const min = rule.min || 3;
    const max = rule.max || 5;
    const regu = eval('/^\\d{' + min + '}$|^\\d{' + max + '}$/');
    if (value && !regu.test(value)) {
      return callback(new Error(`请输入${min}位或${max}数字的${prefix}`));
    } else {
      return callback();
    }
  },
  // 车牌号校验 含新能源
  carPhoneRule: function(rule, value, callback) {
    const plateNumber = /^([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}(([0-9]{5}[DF])|([DF]([A-HJ-NP-Z0-9])[0-9]{4})))|([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳]{1})$/;
    if (plateNumber.test(value)) {
      callback();
    } else {
      callback(new Error('车牌号码输入格式有误，请重新输入！'));
    }
  },
};
/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}
