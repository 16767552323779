<template>
  <el-dropdown class="layout_user_wrapper" @command="handleCommand">
    <router-link class="user_link" :to="!userInfo ? { name: 'login', params: { type } } : { name: 'user' }">
      <div class="person">
        <div class="person_img">
          <img :src="!userInfo ? require('@/assets/images/profilephoto_01a.png') : userInfo.avatarUrl" alt="" />
        </div>
        <div class="person_no_login">
          <div class="body"></div>
          <span v-if="!userInfo" @click="handleLogin('login')">登录</span>
          <span @click="handleLogin('register')" v-if="!userInfo">注册</span>
          <span v-else>个人中心</span>
        </div>
      </div>
    </router-link>
    <el-dropdown-menu v-if="userInfo" slot="dropdown">
      <el-dropdown-item command="logout">退出</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  name: 'layout_user_wrapper',
  data() {
    return {
      type: '',
      userInfo: {},
    };
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
  },
  methods: {
    handleLogin(type) {
      this.type = type;
      setTimeout(() => {
        this.$router.push({
          name: 'login',
          params: {
            type,
          },
        });
      }, 100);
    },
    handleCommand(e) {
      if (e === 'logout') {
        sessionStorage.removeItem('menu');
        localStorage.removeItem('identity');
        localStorage.removeItem('dyncRoute');
        localStorage.removeItem('userInfo');
        localStorage.removeItem('token');
        this.$router.push({
          name: 'login',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.layout_user_wrapper {
  float: right;
  height: 36px;
  padding-top: 16px;
  min-width: 120px;
  .user_link {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .person {
    width: 120px;
    height: 36px;
    display: flex;
    align-items: center;
    align-self: center;
    position: relative;
    .person_img {
      width: 36px;
      height: 36px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      z-index: 133;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .person_no_login {
      position: absolute;
      width: 100%;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 28px;
      display: flex;
      justify-content: space-between;
      padding: 4px 16px 6px 46px;
      box-sizing: border-box;
      // background: linear-gradient(270deg, #c3d2db 0%, #ffffff 100%);
      border-radius: 18px;
      font-family: MicrosoftYaHei;
      font-size: 12px;
      color: #ffffff;
      align-items: center;
      text-align: left;
      font-style: normal;
      .body {
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        height: 28px;
        display: flex;
        justify-content: space-between;
        padding: 4px 16px 6px 46px;
        box-sizing: border-box;
        background: linear-gradient(270deg, #c3d2db 0%, #ffffff 100%);
        border-radius: 18px;
        opacity: 0.4;
        pointer-events: none;
      }
    }
  }
}
.el-dropdown-menu {
  width: 120px;
  text-align: center;
  z-index: 99999 !important;
}
</style>
