<template>
  <div class="page-home">
    <!-- 预留swiper -->
    <div class="swiper_wrapper">
      <el-carousel @change="getIndex">
        <el-carousel-item v-for="item in bannerList" :key="item">
          <div
            class="swiper"
            :style="{
              background: `url('${item.picture}') no-repeat center`,
              backgroundSize: 'cover',
              height: '100%',
            }"
          >
            <div class="content">
              <div class="swpiper-title">{{ item.title }}</div>
              <div class="swpiper-desc" v-html="item.info"></div>
              <div class="swpiper-time">报名时间：{{ item.beginTime }} -- {{ item.endTime }}</div>
              <div class="button cursor" @click="goBm(item)">{{ statusType == 1 ? '报名中' : statusType == 2 ? '立即预约' : '已结束' }}</div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 首页样式 -->
    <div class="page_content">
      <BannerBar />
      <PageCard style="margin-top: 33px" :title="'赛事信息'">
        <template v-slot:desc>
          <div class="desc">
            <span>从报名到证书查询，助力冲上云霄。</span>
            <span style="cursor: pointer" @click="handlePush('eventinformation')">查看更多赛事信息 >></span>
          </div>
        </template>
        <template v-slot:content>
          <EventInformationCard />
        </template>
      </PageCard>
      <div class="aviation_consulting">
        <div class="body"></div>
        <PageCard class="aviation_consulting_card" :title="'航赛资讯'">
          <template v-slot:desc>
            <div class="desc">
              <span>随足不出户了解相关信息，</span>
              <span style="cursor: pointer" @click="handlePush('aviationcompetition')">查看全部航赛资讯 >></span>
            </div>
          </template>
          <template v-slot:content>
            <AvaiationConsulting />
          </template>
        </PageCard>
      </div>
      <!-- 飞行榜单 -->
      <PageCard class="page_card_teacher" :title="'飞行榜单'">
        <template v-slot:desc>
          <div class="desc">
            <span>学习你追我赶，成绩再创辉煌。</span>
            <span class="pointer" @click="handlePush('flightRanking')">查看更多飞行榜单 >></span>
          </div>
        </template>
        <template v-slot:content>
          <FlightRankingSwiper />
        </template>
      </PageCard>
      <!-- 认证名师 -->
      <PageCard class="page_card_teacher" :title="'认证名师'">
        <template v-slot:desc>
          <div class="desc">
            <span>名师汇集，保证教学质量与学习效果。</span>
            <span class="pointer" @click="handlePush('certifiedmaster')">查看更多认证名师 >></span>
          </div>
        </template>
        <template v-slot:content>
          <TeacherSwiper />
        </template>
      </PageCard>

      <!-- 航校风采 -->
      <div class="school_wrapper">
        <div class="body"></div>
        <PageCard class="school_wrapper_pageCard" type="dark" style="margin-top: 60px; float: left" :title="'航校风采'">
          <template v-slot:desc>
            <div class="desc">
              <span>航校风采尽情展示。</span>
              <span style="cursor: pointer" @click="handlePush('schoolstyle')">查看更多航校信息 >></span>
            </div>
          </template>
          <template v-slot:content>
            <SchoolSwiper />
          </template>
        </PageCard>
      </div>

      <!-- 更多赛事 -->
      <PageCard style="margin-top: 60px; float: left; padding-bottom: 60px; box-sizing: border-box" :title="'更多赛事'">
        <template v-slot:desc>
          <div class="desc">
            <span style="color: #333">更多相关赛事，助力冲上云霄。</span>
          </div>
        </template>
        <template v-slot:content>
          <More />
        </template>
      </PageCard>
    </div>
  </div>
</template>

<script>
import BannerBar from '@/views/components/bannerBar';
import PageCard from '@/views/components/pageCard.vue';
import EventInformationCard from '@/views/components/eventInformationCard.vue';
import AvaiationConsulting from '../../components/avaiationConsulting.vue';
import FlightRankingSwiper from '../../components/FlightRankingSwiper.vue';
import TeacherSwiper from '../../components/teacherSwiper.vue';
import SchoolSwiper from '../../components/SchoolSwiper.vue';
import More from '../../components/more.vue';
import { getInfo } from '@/api/person/mine.js';

import { getregistrationList } from '@/api/index';
export default {
  name: 'login',
  components: { BannerBar, PageCard, EventInformationCard, AvaiationConsulting, FlightRankingSwiper, TeacherSwiper, SchoolSwiper, More },
  data() {
    return {
      statusType: 1, //1.报名中 2.立即预约 3.已结束
      bannerList: [],
      userInfo: {},
    };
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (this.userInfo?.id) {
      this.getMineInfo();
    }
    this.getRegistrationList(); // 获取报名列表
  },

  methods: {
    async getMineInfo() {
      let result = await getInfo(this.userInfo?.id);
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      userInfo.avatarUrl = result.data.avatar;
      userInfo.gender = result.data.gender;
      userInfo.identity = result.data.identity;
      userInfo.nickName = result.data.nickname;
      userInfo.userId = result.data.userId;
      userInfo.teamsName = result.data.teamsName;
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
    },
    getIndex(index) {
      const startTimestamp = new Date(this.bannerList[index].beginTime).getTime(); // 将要比较的时间转为毫秒数
      const endTimestamp = new Date(this.bannerList[index].endTime).getTime(); // 将要比较的时间转为毫秒数
      const currentTimestamp = new Date().getTime();
      if (startTimestamp < currentTimestamp && currentTimestamp < endTimestamp) {
        this.statusType = 1;
      } else if (startTimestamp > currentTimestamp) {
        this.statusType = 2;
      } else {
        this.statusType = 3;
      }
    },
    async getRegistrationList() {
      let result = await getregistrationList({
        pageNo: 1,
        pageSize: 5,
        isPub: 1,
      });
      this.bannerList = result.data.items;
      if (this.bannerList.length === 1) {
        this.getIndex(0);
      }
    },
    handlePush(urlName) {
      this.$router.push({
        path: `/${urlName}`,
      });
    },
    goBm(item) {
      if (!this.userInfo) {
        this.$router.push({
          path: '/login',
        });
      } else {
        if (this.statusType === 3) {
          this.$message.warning({
            message: '该报名已结束',
            type: 'warning',
            customClass: 'message-zindex',
          });
        } else if ((this.userInfo.identity === 1 && item.raceType === 0) || (this.userInfo.identity === 3 && item.raceType === 1)) {
          this.$router.push({
            path: '/user/game/bm',
            query: {
              item,
            },
          });
        } else if (this.userInfo.identity === 2) {
          this.$message.warning({
            message: '教练暂不可报名，请切换账号',
            type: 'warning',
            customClass: 'message-zindex',
          });
        } else {
          this.$message.warning({
            message: '身份类型和赛事类型不匹配，请切换账号',
            type: 'warning',
            customClass: 'message-zindex',
          });
        }
      }
    },
  },
};
</script>

<style lang="scss">
// .layout-wrapper {
//   display: block;
// }
//设置message层级防止被遮挡
.message-zindex {
  z-index: 10000 !important;
}
.swiper_wrapper {
  position: absolute;
  width: 100%;
  height: $base_swiper_height;
  // background-color: #2c71d8;
  left: 0;
  top: 0;
  .el-carousel,
  .el-carousel__container {
    widows: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      // height: calc(100% - #{$base_layout_height});
    }
  }
  .swiper {
    position: relative;
    .content {
      position: absolute;
      width: $base_width;
      bottom: 116px;
      left: 0;
      right: 0;
      margin: auto;
      .swpiper-title {
        height: 67px;
        font-family: MicrosoftYaHeiLight;
        font-size: 50px;
        color: #ffffff;
        line-height: 67px;
        text-align: left;
        font-style: normal;
        margin-bottom: 24px;
      }
      .swpiper-desc {
        height: 24px;
        font-family: MicrosoftYaHei;
        font-size: 18px;
        color: #ffffff;
        line-height: 24px;
        text-align: left;
        font-style: normal;
        margin-bottom: 14px;
      }
      .swpiper-time {
        height: 24px;
        font-family: MicrosoftYaHei;
        font-size: 18px;
        color: #ffffff;
        line-height: 24px;
        text-align: left;
        font-style: normal;
        margin-bottom: 26px;
      }
      .button {
        width: 180px;
        height: 40px;
        background: linear-gradient(270deg, #f2982c 0%, #de783a 100%);
        border-radius: 4px;
        font-family: MicrosoftYaHei;
        font-size: 18px;
        color: #ffffff;
        line-height: 40px;
        text-align: center;
        font-style: normal;
      }
    }
  }
  .el-carousel__indicators--horizontal {
    bottom: 50px;
  }
}
.page_content {
  position: relative;
  z-index: 2024;
  margin-top: calc(#{$base_swiper_height} - #{$base_layout_height} - 47px);
  .desc {
    width: 100%;
    height: 18px;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #333333;
    line-height: 18px;
    font-style: normal;
    display: flex;
    justify-content: center;
    span {
      &:nth-last-child(1) {
        color: #005bff;
      }
    }
  }
  .aviation_consulting {
    position: relative;
    width: 100%;
    height: 620px;
    float: left;
    .body {
      position: absolute;
      top: 0;
      left: 0;
      height: 620px;
      width: 100vw;
      background: #d6dce5;
      transform: scaleX(2);
      z-index: -1;
    }
  }
  .school_wrapper {
    position: relative;
    width: 100%;
    height: 746px;
    float: left;
    .desc {
      color: #fff;
    }
    .body {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100vw;
      background: #404c58;
      transform: scaleX(2);
      z-index: -1;
    }
  }
  .school_wrapper_pageCard {
    display: flex;
    flex-direction: column;
    height: calc(100% - 64px);
  }
}

.aviation_consulting_card {
  margin-top: 60px;
}
.page_card_teacher {
  margin-top: 60px;
  float: left;
  padding-bottom: 60px;
  box-sizing: border-box;
  min-height: 650px;
}
</style>
