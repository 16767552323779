<template>
  <div class="teacher_wrapper pointer">
    <div class="swiper swiperTeacher">
      <div class="swiper-wrapper">
        <div class="swiper-slide carousel_item_child" @click="handlePush(itemChild)" v-for="itemChild in list" :key="itemChild">
          <div class="carousel_item_child_img">
            <img :src="itemChild.avatar" alt="" />
            <div class="teacher_name">{{ itemChild.nickname }}</div>
          </div>
          <div class="teacher_leval_box">
            <el-tag class="teacher_leval" v-for="item in itemChild.certificationList" :key="item.certification" type="warning" effect="dark">
              {{ item.certification }}
            </el-tag>
          </div>
          <div class="carousel_item_child_content">
            <div class="carousel_item_child_label">基本信息：</div>
            <div class="carousel_item_child_value">{{ itemChild.introductory || '无' }}</div>
          </div>
        </div>
      </div>
      <!-- 如果需要分页器 -->
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>
<script>
import { teacherList } from '@/api/index';
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';
import 'swiper/swiper-bundle';
export default {
  name: 'TeacherSwiper',
  data() {
    return {
      list: [],
      activeIndex: 0,
      mySwiper: null,
    };
  },
  mounted() {
    this.getTeacherList();
    window.addEventListener('popstate', this.initWrapper);
    this.initWrapper();
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.initWrapper);
  },
  methods: {
    async getTeacherList() {
      let result = await teacherList({
        pageNo: 1,
        pageSize: 16,
      });
      result.data.items.forEach((item) => {
        if (item.certificationList) {
          item.certificationList.sort((a, b) => a.certification.length - b.certification.length);
        }
      });
      this.list = result.data.items;
    },
    handleChange(e) {
      this.activeIndex = e;
    },
    handlePush(item) {
      this.$router.push({
        path: '/certifiedmasterDetail',
        query: {
          id: item.userId,
        },
      });
    },
    initWrapper() {
      setTimeout(() => {
        this.mySwiper = new Swiper('.swiperTeacher', {
          autoplay: true,
          slidesPerView: 4,
          loop: true,
          spaceBetween: 30,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          on: {
            beforeLoopFix: function() {
              // 如果滑块不够
              if (this.slides.length < 4) {
                // 禁止循环修复
                this.loopedSlides = 0;
                this.loopDestroy();
              }
            },
          },
        });
      }, 400);
    },
  },
};
</script>
<style lang="scss">
.teacher_wrapper {
  width: 100%;
  height: 450px;
  .swiper-wrapper {
    height: 450px;
  }
  .swiper-pagination {
    /* 如果需要，可以添加对齐方式，例如对齐到父容器的底部 */
    position: absolute;
    /* 这里的父容器需要是相对定位或绝对定位 */
    z-index: 1; /* 确保分页器在所有内容之上 */
  }

  .carousel_item_child {
    width: 290px;
    position: relative;
    height: 420px;
    cursor: pointer;
    background: url('~@/assets/images/MsBK_01.png') no-repeat center;
    background-size: cover;
    margin-right: 18px;
    padding: 38px 31px 40px 43px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:nth-last-child(1) {
      margin-right: 0;
    }

    .carousel_item_child_img {
      width: 160px;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: 160px;
        border-radius: 50%;
      }

      .teacher_name {
        height: 24px;
        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-weight: bold;
        font-size: 18px;
        color: #333333;
        line-height: 24px;
        font-style: normal;
        margin-top: 12px;
      }
    }

    .teacher_leval_box {
      display: flex;
      justify-content: center;
      /* 水平居中 */
      flex-wrap: wrap;
      width: 290px;
      min-height: 74px;
      align-content: flex-start;

      .teacher_leval {
        float: left;
        height: 18px;
        margin: 6px 4px 0 0px;
        height: 18px;
        font-weight: bold;
        font-size: 12px;
        color: #ffffff;
        line-height: 16px;
        text-align: right;
        font-style: normal;
      }
    }

    .carousel_item_child_content {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-top: 18px;

      .carousel_item_child_label {
        width: 100%;
        height: 24px;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #bbbbbb;
        line-height: 24px;
        text-align: left;
        font-style: normal;
      }

      .carousel_item_child_value {
        height: 48px;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #888888;
        line-height: 24px;
        text-align: left;
        font-style: normal;
        // white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }
  }
}
</style>
